import {Subscription} from 'rxjs';
import {AppConfigService, BoErrorHandlerService} from '../../helio-core-services';
import {DataTableAction, DataTableLazyLoadEvent, SearchField} from '../components';
import {ComponentHelper} from './component.helper';
import {Directive} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {isDefinedArray} from '../utilities/general-utilities/arrays.utils';
import {BaseLookup} from './lookup-interfaces';

@Directive()
export abstract class CommonTable<D> extends ComponentHelper implements CommonTableBase {

	loading = false;
	dataKey: string;
	data: D[] = null; // = [];
	tableMessage = '';
	searchFields: SearchField[] = [];
	tableActions: DataTableAction[];
	offset: number;

	cols: any[];
	totalRecords: number;

	constructor(
		protected appConfigService: AppConfigService,
		protected boErrorHandlerService: BoErrorHandlerService
	) {
		super();

		this.tableMessage = this.appConfigService.tableMissingDataMessage;
	}

	beforeTableDataServiceCall(sub$: Subscription, clearData: boolean = true): void {
		this.tableMessage = this.appConfigService.tableMissingDataMessage;
		this.loading = true;

		if (clearData) {
			// this.data = undefined;
		}

		this.releaseSubscriptions(sub$);
	}

	handleDataRequestError(callName: string = '', error: Error): void {
		this.loading = false;
		this.tableMessage = this.appConfigService.genericErrorMessage;
		this.boErrorHandlerService.handleError(error, undefined, callName);
	}

	/**
	 * @todo change signature to abstract to force implementing classes to impl once code has been refactored in currently usage
	 * @param filterData filter params generated by events such as AdvancedSearch or sorting.
	 * @param forceSearchParam is intended for internal usage within the implementing class (i.e. not via callbacks),
	 * when undefined, signals that a new HttpParams should be computed, e.g., when new AdvancedSearch request
	 * is received; If a valid value is specified, then this is used and filterData arg is ignored all together - example usage
	 * is when merely changing user IDs, and the old search params (applied by UI) are still relevant.
	 */
	public getTableData(filterData?: DataTableLazyLoadEvent, forceSearchParam?: HttpParams): void {
	}

	// TODO Refactor to protected abstract initDataTable(): void; to require impl - will then have to update naming in all affected places
	protected initDataTable(): void {
	};

	// TODO Refactor to protected abstract fetchLookups(): void; to require impl - will then have to update naming in all affected places
	protected fetchLookups(): void {
	};

	/**
	 * Return the default array of table actions (i.e. omitting those requiring dynamic lookup) with a new reference.
	 * @summary each time the method is called a new array, with a new reference is returned, ensuring that when assigned
	 * to {@link #tableActions} this is used as a means of signalling to @Input that the dataset has changed.
	 */
	getDefaultTableActions(): DataTableAction[] {
		return [{
			menuItem: {
				label: 'Export to CSV',
				icon: 'ui-icon-insert-drive-file'
			},
			callback: (callbackObj) => {
				this.exportToCSV(callbackObj.data, callbackObj.isAllDataSelected);
			},
			isRowAction: false
		}];
	}

	reassignLookupOpts<L extends BaseLookup>(lookup: L[], prop: string) {
		if (isDefinedArray(lookup)) {

			// Update the previously added fieldName object by reference
			const temp = this.searchFields.find(value => {
				return value.property === prop;
			});

			if (temp) {
				temp.listOptions = lookup;
			}
		}
	}

	/**
	 * @todo change signature to abstract to force implementing classes to impl once code has been refactored in currently usage
	 */
	exportToCSV(selectedData?: D[], isAllDataSelected?: boolean): void {
	}

}

export interface CommonTableBase {
	// data?: any[]; // consider changing this later to derive from a CommonTableBase<T>, and make required
	loading: boolean;
	dataKey: string;
	cols: any[];
	totalRecords: number;
	tableMessage: string;
	searchFields: SearchField[];
}

// CommonNestedTableBase<P, C> {
export class CommonNestedTableBase<C> {
	// data?: any[]; // consider changing this later to derive from a CommonTableBase<T>, and make required
	index: number;
	loading = true;
	// parentData: P;
	data: C[] = null; // = [];
	cols: any[];
	totalRecords: number;
	tableMessage: string;
	searchFields: SearchField[];
}
