import {TableDataResponse} from '../../components';
import {AccountRestriction} from '../../models/player/account-restriction.model';
import {AccountSubscription} from '../../models/player/subscription.model';
import {Message} from '../../models/player/message.model';
import {WalletTransaction} from '../../models/finance/wallet-transaction.model';
import {BankCard, TransferDetails} from '../../models/finance/transfer-information.model';
import {PaymentProvidersFallbackOrder} from '../../models/finance/payment-providers-fallback.model';
import {PaymentProvidersDistribution} from '../../models/finance/payment-providers-dist.model';
import {WithdrawalReq} from '../../../player-management/shared/models/withdrawal.model';

export function dummyAccountRestrictionData(): TableDataResponse<AccountRestriction> {
	return {
		totalRowCount: 3,

		resultSet: [
			{
				playerRestrictionID: 2,
				playerRestrictionTypeID: 41,
				restrictionName: 'Self-Exclusion',
				originatingChannel: 'Website',
				placedBy: 'dummydata@heliogaming.com',
				datePlaced: new Date('11/25/1952'),
				dateExpiry: new Date('11/25/1955'),
				scheduledRemoval: new Date('11/25/1955'),
				isActive: false,
				reason: 'Testing comment'
			},
			{
				playerRestrictionID: 1,
				playerRestrictionTypeID: 42,
				restrictionName: 'Time-Out',
				originatingChannel: 'Website',
				placedBy: 'dummydata@heliogaming.com',
				datePlaced: new Date('12/25/1981'),
				dateExpiry: new Date('12/30/1981'),
				scheduledRemoval: new Date('12/30/1981'),
				isActive: false,
				reason: 'Testing comment'
			},
			{
				playerRestrictionID: 3,
				playerRestrictionTypeID: 43,
				restrictionName: 'Account Deactivation',
				originatingChannel: 'Website',
				placedBy: 'dummydata@heliogaming.com',
				datePlaced: new Date('07/01/1991'),
				dateExpiry: new Date('10/01/1991'),
				scheduledRemoval: new Date('10/01/1991'),
				isActive: false,
				reason: 'Testing comment'
			}
		],
		offset: 0,
		take: 10
	}
}

export function dummySubscriptionsData(): TableDataResponse<AccountSubscription> {
	return {
		totalRowCount: 3,

		resultSet: [
			{
				recurringPurchaseID: 2,
				numbers: [12, 11, 1, 49, 5],
				numbersStr: '12, 11, 1, 49, 5',
				duration: '1 Month',
				createdDate: new Date('01/14/1967'),
				startDate: new Date('02/22/1967'),
				endDate: new Date('03/22/1988'),
				status: 'Cancelled'
			}
		],
		offset: 0,
		take: 10
	}
}

export function dummyMessagesData(): TableDataResponse<Message> {
	return {
		totalRowCount: 3,

		resultSet: [
			{
				messageID: 23447,
				subject: 'Mahzooz weekly draw results',
				date: new Date('07/11/1988'),
				email: 'joe.bloggs@example.com',
			},
			{
				messageID: 23423,
				subject: 'Profile updated successfully',
				date: new Date('02/22/1967'),
				email: 'joe.bloggs@example.com',
			}
		],
		offset: 0,
		take: 10
	}
}

export const dummyWalletTransactionData: TableDataResponse<WalletTransaction> = {
	totalRowCount: 2,

	resultSet: [
		{
			balanceTransactionID: 1,
			balanceActionID: 55,
			playerID: 350,
			timestamp: new Date('12/25/1981'),
			transactionType: 'Purchase',
			transactionTypeID: 1,
			comment: 'This is a dummy data.',
			currencyID: 60,
			currencyCode: 'AED',
			playerBalanceTypeID: 1,
			vat: 0,
			vatPercentage: 5.00,
			walletType: 'Credit Balance',
			amount: 35,
			walletBalance: 200,
			totalBalance: 175
		},
		{
			balanceTransactionID: 2,
			balanceActionID: 7,
			playerID: 669,
			timestamp: new Date('11/13/1985'),
			transactionType: 'Purchase',
			transactionTypeID: 1,
			comment: 'This is a dummy data.',
			currencyID: 60,
			currencyCode: 'AED',
			playerBalanceTypeID: 1,
			vat: 0,
			vatPercentage: 5.00,
			walletType: 'Credit Balance',
			amount: 200,
			walletBalance: 0,
			totalBalance: 200
		}
	],
	offset: 0,
	take: 10
}

export const dummyWalletCardsData: TableDataResponse<BankCard> = {
	totalRowCount: 2,

	resultSet: [
		{
			playerPaymentAccountID: 54,
			paymentMethodID: 41,
			paymentMethodName: 'visa',
			src: '/assets/lottery-logos/visa-logo-blue.png',
			/*expiryYear: 1992,
			expiryMonth: 11,*/
			expiry: '11/1992',
			accountReference: '5337 ******* 1234',
			dateCreated: new Date('11/13/1985'),
			lastUsed: new Date('01/03/1988'),
			isDefault: false,
			tenantID: 101,
			playerID: 222,
			holderName: 'Travis Britt'
		},
		{
			playerPaymentAccountID: 67,
			paymentMethodID: 42,
			paymentMethodName: 'mastercard',
			src: '/assets/lottery-logos/mastercard-logo.png',
			/*expiryYear: 1994,
			expiryMonth: 7,*/
			expiry: '7/1994',
			accountReference: '7889 ******* 9875',
			dateCreated: new Date('07/01/1987'),
			lastUsed: new Date('01/14/1987'),
			isDefault: true,
			tenantID: 101,
			playerID: 222,
			holderName: 'Travis Britt'
		},
		{
			playerPaymentAccountID: 67,
			paymentMethodID: 43,
			paymentMethodName: 'unionpay',
			src: '/assets/lottery-logos/unknown_card_type.png',
			/*expiryYear: 1969,
			expiryMonth: 12,*/
			expiry: '12/1969',
			accountReference: '2222 ******* 8844',
			dateCreated: new Date('07/01/1965'),
			lastUsed: new Date('01/14/1967'),
			isDefault: false,
			tenantID: 101,
			playerID: 222,
			holderName: 'Travis Britt'
		}
	],
	offset: 0,
	take: 10
}

export const dummyFallbacksData: TableDataResponse<PaymentProvidersFallbackOrder> = {
	totalRowCount: 3,

	resultSet: [
		{
			paymentServiceProviderID: 65,
			fallbackOrder: 1,
			name: 'NGenius',
			currentNumberOfPlayers: 54_673
		},
		{
			paymentServiceProviderID: 74,
			fallbackOrder: 2,
			name: 'Fiserv',
			currentNumberOfPlayers: 24_883
		},
		{
			paymentServiceProviderID: 43,
			fallbackOrder: 3,
			name: 'Amazon Payment Services',
			currentNumberOfPlayers: 0
		}
	],
	offset: 0,
	take: 10
}

export const dummyDistributionsData: TableDataResponse<PaymentProvidersDistribution> = {
	totalRowCount: 3,

	resultSet: [
		{
			paymentServiceProviderID: 65,
			name: 'NGenius',
			currentNumberOfPlayers: 54_673,
			isActivated: true,
			distribution: 100
		},
		{
			paymentServiceProviderID: 74,
			name: 'Fiserv',
			currentNumberOfPlayers: 24_883,
			isActivated: true,
			distribution: 25
		},
		{
			paymentServiceProviderID: 43,
			name: 'Amazon Payment Services',
			currentNumberOfPlayers: 0,
			isActivated: false,
			distribution: 25
		}
	],
	offset: 0,
	take: 10
}

export const dummyWithdrawalRequestData: TableDataResponse<WithdrawalReq> = {
	totalRowCount: 2,

	resultSet: [
		{
			paymentOrderID: 1,
			fullTenantID: 33,
			playerID: 45,
			fullName: 'Maria Jueberg',

			createdDate: new Date('07/01/1965'),
			paymentMethod: 'Dummy - Bank Transfer',

			kycStatus: 'Pending',

			currencyCode: 'AED',
			amount: 1000,

			paymentStateID: 1,
			paymentState: 'Dummy - Pending'
		},
		{
			paymentOrderID: 2,
			fullTenantID: 33,
			playerID: 16,
			fullName: 'Joe Bloggs',

			createdDate: new Date('12/15/1965'),
			paymentMethod: 'Dummy - Bank Transfer',

			kycStatus: 'Pending',

			currencyCode: 'AED',
			amount: 1000,

			paymentStateID: 2,
			paymentState: 'Dummy - Pending'
		}
	],
	offset: 0,
	take: 10
}

export const dummyBankDetails: TransferDetails = {
	paymentOrderID: 23,
	beneficiaryName: 'Joe Bloggs',
	nationalIDNumber: '320XGH62',

	bankName: 'NatWest',
	bankAccountNumber: '12345678',
	iban: 'NWCUK45',
	swiftCode: 'BUKBGB22',
	routingNumber: '123456789',
	ifsc: 'ABS423',
	bankCity: 'Cardiff',
	// branchCountry: 'UNITED KINGDOM',
	bankBranchCountryID: 240,
	bankBranch: 'Cardiff Central',
	// countryOfBirth: 'UNITED KINGDOM',
	// countryOfBirthId: 240,
	// placeOfBirth: 'Llantwit'
}
