import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ValidRows} from '../components';

@Injectable({
	providedIn: 'root'
})
export class TableStateService {

	private playersFilterSub = new BehaviorSubject<TableFilters>(null);

	private tabIndexPlayerTabviewSub = new BehaviorSubject<number>(0);
	tabIndexPlayerTabview$ = this.tabIndexPlayerTabviewSub.asObservable();

	private playerTrailFiltersSub = new BehaviorSubject<TableFilters>(null);

	// private _storedFiltersSub = new BehaviorSubject<StoredFilter>(null);
	// _storedFilters$ = this._storedFiltersSub.asObservable();

	private partHistoryFiltersSub = new BehaviorSubject<TableFilters>(null);
	private rewardPointTabFiltersSub = new BehaviorSubject<TableFilters>(null);
	private sessionTabFiltersSub = new BehaviorSubject<TableFilters>(null);
	private subscriptionTabFiltersSub = new BehaviorSubject<TableFilters>(null);
	private playerAuditTabFiltersSub = new BehaviorSubject<TableFilters>(null);
	private freeTicketTabFiltersSub = new BehaviorSubject<TableFilters>(null);
	private accountRestrictionTabFiltersSub = new BehaviorSubject<TableFilters>(null);
	private walletTransactionTabFiltersSub = new BehaviorSubject<TableFilters>(null);
	private kycTabFiltersSub = new BehaviorSubject<TableFilters>(null);
	private kycMgTabFiltersSub = new BehaviorSubject<TableFilters>(null);

	private walletTransactionFiltersSub = new BehaviorSubject<TableFilters>(null);
	private kycFiltersSub = new BehaviorSubject<TableFilters>(null);
	private kycMgFiltersSub = new BehaviorSubject<TableFilters>(null);

	private readonly filterBehaviorSubs: Array<StoredFilter> = [
		{name: 'partHistoryTab', filter: this.partHistoryFiltersSub, defaultRowsPerPage: 10},
		{name: 'rewardPointTab', filter: this.rewardPointTabFiltersSub, defaultRowsPerPage: 50},
		{name: 'sessionTab', filter: this.sessionTabFiltersSub, defaultRowsPerPage: 10},
		{name: 'subscriptionTab', filter: this.subscriptionTabFiltersSub, defaultRowsPerPage: 10},
		{name: 'playerAuditTab', filter: this.playerAuditTabFiltersSub, defaultRowsPerPage: 50},
		{name: 'freeTicketTab', filter: this.freeTicketTabFiltersSub, defaultRowsPerPage: 25},
		{name: 'accountRestrictionTab', filter: this.accountRestrictionTabFiltersSub, defaultRowsPerPage: 10},
		{name: 'walletTransactionTab', filter: this.walletTransactionTabFiltersSub, defaultRowsPerPage: 10},
		{name: 'kycTab', filter: this.kycTabFiltersSub, defaultRowsPerPage: 10},
		{name: 'kycManagementTab', filter: this.kycMgTabFiltersSub, defaultRowsPerPage: 10},
		{name: 'walletTransaction', filter: this.walletTransactionFiltersSub, defaultRowsPerPage: 10},
		{name: 'kyc', filter: this.kycFiltersSub, defaultRowsPerPage: 10},
		{name: 'kycManagement', filter: this.kycMgFiltersSub, defaultRowsPerPage: 10}
	];

	constructor() {
		// Init these from here to reduce service code in table components
		this.getPlayerTabviewIndex(true);

		this.getPlayersFilters(true);
		this.getPlayerTrialFilters(true);

		this.filterBehaviorSubs.map(entry => entry.name)
			.forEach(name => this.getTableFilters(name, true));
	}

	/**
	 * @param orderBy orderBy
	 * @param rowsPerPage rowsPerPage
	 * @param setOnlyInStorage use in onDestroy to persist the value in LocalStorage. Ensuring thereby that performance overheads
	 * are not incurred from repeated querying of the storage.
	 */
	setPlayersFilters({orderBy, rowsPerPage}: TableFilters, setOnlyInStorage?: boolean): void {
		rowsPerPage = rowsPerPage || 10

		if (setOnlyInStorage) {
			// console.log('>>>>>>>> setPlayerTrialFilters with:', {orderBy, rowsPerPage})
			this._setStoredState('players', {orderBy, rowsPerPage});
			return;
		}

		this.playersFilterSub.next({orderBy, rowsPerPage});
	}

	getPlayersFilters(initFromStorage: boolean = true): TableFilters {
		if (initFromStorage) {
			const storedValue = this._getStoredState<object>('players') as TableFilters;
			// console.log('### getPlayerTrialFilters: rowsPerPage = ', storedValue['rowsPerPage']);
			this.playersFilterSub.next(storedValue);
		}

		const value = this.playersFilterSub.value;
		// Handle scenario where the filter has not yet been initialised
		if (!value) {
			this.playersFilterSub.next({rowsPerPage: 10});
		}

		return this.playersFilterSub.value;
	}

	setPlayerTabviewIndex(index: number, setOnlyInStorage?: boolean): void {
		const tabIndex = index ?? 0;

		if (setOnlyInStorage) {
			this._setStoredState('playerTabviewIndex', tabIndex);
			return;
		}

		this.tabIndexPlayerTabviewSub.next(index);
	}

	getPlayerTabviewIndex(initFromStorage: boolean = false): number {
		if (initFromStorage) {
			const storedValue = this._getStoredState<number>('playerTabviewIndex') ?? this.tabIndexPlayerTabviewSub.value;
			// console.log('storedVal =', storedValue)
			this.tabIndexPlayerTabviewSub.next(storedValue);
		}

		return this.tabIndexPlayerTabviewSub.value;
	}

	setPlayerTrialFilters({orderBy, rowsPerPage}: TableFilters, setOnlyInStorage?: boolean): void {
		rowsPerPage = rowsPerPage || 10

		if (setOnlyInStorage) {
			// console.log('>>>>>>>> setPlayerTrialFilters with:', {orderBy, rowsPerPage})
			this._setStoredState('playerTrail', {orderBy, rowsPerPage});
			return;
		}

		this.playerTrailFiltersSub.next({orderBy, rowsPerPage});
	}

	getPlayerTrialFilters(initFromStorage?: boolean): TableFilters {
		if (initFromStorage) {
			const storedValue = this._getStoredState<object>('playerTrail') as TableFilters;
			// console.log('### getPlayerTrialFilters: rowsPerPage = ', storedValue['rowsPerPage']);
			this.playerTrailFiltersSub.next(storedValue);
		}

		const value = this.playerTrailFiltersSub.value;
		// Handle scenario where the filter has not yet been initialised
		if (!value) {
			this.playerTrailFiltersSub.next({rowsPerPage: 10});
		}

		return this.playerTrailFiltersSub.value;
	}

	private getSub(subName: keyof TableState): StoredFilter | null {
		return this.filterBehaviorSubs
			.find(sub => sub.name === subName);
	}

	private getSubName(subName: keyof TableState): BehaviorSubject<TableFilters> | null {
		return this.filterBehaviorSubs
			.find(sub => sub.name === subName)?.filter;
	}

	setTableFilters(name: keyof TableState, {orderBy, rowsPerPage}: TableFilters, setOnlyInStorage?: boolean): void {
		const filterSub = this.getSubName(name);
		if (!filterSub) {
			console.error(`getStoredFilters: sub not attached or created for ${name}.`);
			return null;
		}

		rowsPerPage = rowsPerPage || this.getSub(name).defaultRowsPerPage || 10

		if (setOnlyInStorage) {
			this._setStoredState(name, {orderBy, rowsPerPage});
			return;
		}

		filterSub.next({orderBy, rowsPerPage});
	}

	getTableFilters(name: keyof TableState, initFromStorage?: boolean): TableFilters {
		const filterSub = this.getSubName(name);
		if (!filterSub) {
			console.error(`getStoredFilters: sub not attached or created for ${name}.`);
			return null;
		}

		if (initFromStorage) {
			const storedValue = this._getStoredState<object>(name) as TableFilters;
			filterSub.next(storedValue);
		}

		const value = filterSub.value;
		// Handle scenario where the filter has not yet been initialised
		if (!value) {
			filterSub.next({rowsPerPage: this.getSub(name).defaultRowsPerPage || 10});
		}

		return filterSub.value;
	}

	private _getStoredState<T>(key: keyof TableState): T {

		let value: any = localStorage.getItem('playerTableState');

		if (value) {
			value = JSON.parse(value)[key];

			try {
				value = JSON.parse(value);
			} catch (e) {
				// Not a JSON string, return as is
			}

			return value as T;
		}

		return null;
	}

	private _setStoredState<T>(key: keyof TableState, value: string | number | object): void {
		const storedState = localStorage.getItem('playerTableState');

		const temp: TableState = storedState ? JSON.parse(storedState) : {};
		temp[key] = value as any;

		localStorage.setItem('playerTableState', JSON.stringify(temp));
	}
}

export interface TableFilters {
	orderBy?: string;
	rowsPerPage: ValidRows;
}

export interface TableState {
	playerTabviewIndex?: number;

	players?: TableFilters;
	playerTrail?: TableFilters;
	partHistoryTab?: TableFilters;
	rewardPointTab?: TableFilters;
	sessionTab?: TableFilters;
	subscriptionTab?: TableFilters;
	playerAuditTab?: TableFilters;
	freeTicketTab?: TableFilters;
	accountRestrictionTab?: TableFilters;
	walletTransactionTab?: TableFilters;
	kycTab?: TableFilters;
	kycManagementTab?: TableFilters;

	walletTransaction?: TableFilters;
	kyc?: TableFilters;
	kycManagement?: TableFilters;
}

export interface StoredFilter {
	name: keyof TableState,
	filter: BehaviorSubject<TableFilters>,
	defaultRowsPerPage: ValidRows
}
