import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {noGameLotteryImageURL} from '../../../../../draw-processing';
import {PublishDrawResultBase, WinnerBreakdown} from '../../../../models/games/publish-draw-results.model';
import {
	AppConfigService,
	BoErrorHandlerService,
	ConfirmDialogDisplayService,
	ToastDisplayService
} from '../../../../../helio-core-services';
import {ActivatedRoute} from '@angular/router';
import {getSystemLocale} from '../../../../utilities/general-utilities/string.utility';
import {BehaviorSubject} from 'rxjs';
import * as ColumnNames from '../../../../constants/ui-db-name-mappings';

@Component({
	selector: 'he-draw-results-table',
	templateUrl: './draw-results-table.component.html',
	styleUrls: ['./draw-results-table.component.scss']
})
export class DrawResultsTableComponent implements OnInit {

	private _drawResult$ = new BehaviorSubject<PublishDrawResultBase>(null);

	@Input() loading = false;
	@Output() publishResult = new EventEmitter<PublishDrawResultBase>();

	protected readonly getSystemLocale = getSystemLocale;

	readonly RAFFLE_GAME_ID = 7; // 'SimpleLotto' is 1, and all 'Raffle' is assumed to be 7

	dataKey: string;
	data: WinnerBreakdown[] = null; // = [];
	tableMessage = '';

	cols: any[];
	totalRecords: number;

	submitLabel: string;

	protected readonly noGameLotteryImageURL = noGameLotteryImageURL;

	constructor(protected appConfigService: AppConfigService,
				private route: ActivatedRoute,
				private confirmationDisplayService: ConfirmDialogDisplayService,
				private toastDisplayService: ToastDisplayService,
				protected boErrorHandlerService: BoErrorHandlerService) {
		this.tableMessage = this.appConfigService.tableMissingDataMessage;
		this.dataKey = ColumnNames.WIN_BREAKDOWN_PRIZE_TIER.DB;
	}

	@Input() set drawResult(value: PublishDrawResultBase) {
		this._drawResult$.next(value);
	}

	get drawResult() {
		return this._drawResult$.getValue();
	}

	ngOnInit() {
		this._drawResult$.asObservable().subscribe({
			next: value => {
				if (value) {
					this.data = value.winnerBreakdown;
					this.totalRecords = value.winnerBreakdown.length;

					this.submitLabel = 'Publish ' + this.drawResult.gameGroupName;
				}
			}
		});

		this.cols = [
			{
				field: ColumnNames.WIN_BREAKDOWN_PRIZE_TIER.DB,
				header: ColumnNames.WIN_BREAKDOWN_PRIZE_TIER.UI,
				sortable: false
			}
		];

		const isRaffle = this.drawResult?.gameTypeID === this.RAFFLE_GAME_ID ;

		if (!isRaffle) {
			// Worst case scenario the column is shown as "N/A"
			this.cols.push({
				field: ColumnNames.WIN_BREAKDOWN_CRITERIA.DB,
				header: ColumnNames.WIN_BREAKDOWN_CRITERIA.UI,
				sortable: false
			});
		}

		this.cols.push(
			{
				field: ColumnNames.WIN_BREAKDOWN_PRIZE_PER_WINNER.DB,
				header: ColumnNames.WIN_BREAKDOWN_PRIZE_PER_WINNER.UI,
				sortable: false,
				localeCurrencyCodeField: 'prizeCurrencySymbol'
			},
			{
				field: ColumnNames.WIN_BREAKDOWN_NUM_WINNERS.DB,
				header: ColumnNames.WIN_BREAKDOWN_NUM_WINNERS.UI,
				sortable: false
			}
		);
	}

	onPublishClicked() {
		this.publishResult.emit(this.drawResult);
	}
}
