import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable, of, switchMap, throwError, throwError as _throw} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ServiceController} from '../../shared/utilities/service-utilities/service-controller.urls';
import {FormatParams, ServiceHeadersConfig} from '../../shared/utilities/service-utilities/service-headers.config';
import {ServiceAction} from '../../shared/utilities/service-utilities/service-action.urls';
import {ResponseBodyType} from '../../shared/enums/response-body-type.enum';
import {ServiceUrlsUtility} from '../../shared/utilities/service-utilities/service-urls.utility';
import {ServiceHeadersUtility} from '../../shared/utilities/service-utilities/service-headers.utility';
import {ServiceRequestBodyUtility} from '../../shared/utilities/service-utilities/service-request-body.utility';
import {ServiceError} from '../../shared/models/general/service-error.model';
import {fromFetch} from 'rxjs/internal/observable/dom/fetch';
import {GetQueryData, TableDataResponse} from '../../shared';
import {
	API_DATA_INVALID, FILTER_URL_PARAM, FORMAT_URL_PARAM, OFFSET_URL_PARAM, OFFSET_URL_PARAM_DOLLAR_FREE,
	ORDER_BY_URL_PARAM, QUERY_URL_PARAM, TAKE_URL_PARAM, TAKE_URL_PARAM_DOLLAR_FREE
} from '../../shared/constants';
import {isDefinedArray} from '../../shared/utilities/general-utilities/arrays.utils';

/**
 * Base Service class used to handle HTTP requests to the server
 */
export abstract class BaseServiceImpl {
	/**
	 * @param http Used to pass the Angular Http client which is responsible to making HTTP requests
	 * @param controller Service endpoint as viewed form the Backend
	 * @param baseServiceURL backend associated service URL segment
	 * @param headersConfig Object containing the headers configuration for the HTTP requests, such as Content-Type and Authorization
	 */
	constructor(
		protected http: HttpClient,
		protected controller: ServiceController,
		protected baseServiceURL: string,
		protected headersConfig: ServiceHeadersConfig = new ServiceHeadersConfig()
	) {
	}

	/**
	 * Function to make an HTTP GET request
	 * @param action Contains the 'action' part of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @param responseBodyType one of @link ResponseBodyType.JSON} | {@link ResponseBodyType.Text} | {@link ResponseBodyType.Blob}
	 * @param controller provides a way to override the constructor set value whilst still being backward compatible.
	 * @param xFormatParams allows customisation of CSV naming and which columns are returned.
	 * @param baseServiceURL allows overriding of the default service (class) value
	 * @returns An Observable of the data returned from the server
	 */
	protected get<T>(
		action?: ServiceAction, value?: any[], searchParams?: HttpParams,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON, controller?: ServiceController,
		xFormatParams?: FormatParams[], baseServiceURL?: string
	): Observable<any> {
		const ctrl = controller ?? this.controller;
		const baseUrl = baseServiceURL ?? this.baseServiceURL;

		const serviceUrl = ServiceUrlsUtility.getUrl(baseUrl, ctrl, action, value);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(
			xFormatParams ? this.headersConfig.attachXFormatParams(xFormatParams) : this.headersConfig);

		const options: any = {
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		options.responseType = this.setResponseType(responseBodyType);

		return this.http.get<T>(serviceUrl, options)
			.pipe(
				catchError(this.handleError)
			);

		// return this.http.get(serviceUrl, options)
		// 	.map(res => this.extractData(res, responseBodyType))
		// 	.catch(this.handleError);
	}

	protected get_fetch<T>(
		action?: ServiceAction, value?: any[], searchParams?: HttpParams,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON, controller?: ServiceController, xFormatParams?: FormatParams[]
	): Observable<any> {
		const ctrl = controller ?? this.controller;
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, ctrl, action, value);

		const headers = ServiceHeadersUtility.httpHeaders_fetch(
			xFormatParams ? this.headersConfig.attachXFormatParams(xFormatParams) : this.headersConfig);

		const options: any = {
			headers,
			method: 'GET',
			mode: 'cors'
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		options.responseType = this.setResponseType(responseBodyType);

		/*return fetch(serviceUrl, options).then(res => {
			/!*if (!res) {
				return this.handleError(new HttpErrorResponse({error: new Error(`HE-Error-Msg: Response from ${serviceUrl} was undefined.`)}));
			}*!/
			return res;
		}).catch((err) => {
			this.handleError(err);
		})*/
		return fromFetch(serviceUrl, options).pipe(
			switchMap(res => {
				if (res) {
					return of(res);
				}

				throw new Error('FetchAPI returned null.');
			}),
			catchError(this.handleError)
		)
	}

	private getFileNameFromContentDisposition(str: string): string | null {
		const genericFilename = 'generic_file_name'
		if (!str) {
			return genericFilename;
		}

		// e.g. str is:
		//  "attachment; filename=TransactionalKYC-2024_10_04T14_44_45.zip; filename*=UTF-8''TransactionalKYC-2024_10_04T14_44_45.zip"
		const regex = /filename\*?=.+''(.+?)(?:;|$)/;
		const match = str.match(regex);

		if (match && match[1]) {
			return match[1]
		}

		return genericFilename
	}

	/**
	 * Use to download files where retrieval of their filename is also needed.
	 */
	protected download_for_filename<T>(
		action?: ServiceAction, searchParams?: HttpParams,
		value?: any[], controller?: ServiceController, responseBodyType: ResponseBodyType = ResponseBodyType.JSON,
		method: 'GET' | 'POST' = 'GET', body?: object
	): Observable<any> {
		const ctrl = controller ?? this.controller;
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, ctrl, action, value);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			observe: 'response' as 'body',
			responseType: 'blob' as 'json',
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		options.responseType = this.setResponseType(responseBodyType);

		let observable;

		if (method === 'GET') {
			observable = this.http.get<T>(serviceUrl, options);
		} else {
			observable = this.http.post<T>(serviceUrl, body, options);
		}

		return observable.pipe(
			map((res: HttpResponse<Blob>) => {
				let filename = res.headers.get('content-disposition') ?? null;
				filename = this.getFileNameFromContentDisposition(filename);

				return {data: res.body, filename};
			}),
			catchError(err => {
				// console.error('Error occurred:', err);
				return throwError(() => err);
			})
		);
	}

	protected getCsv(
		action?: ServiceAction,
		value?: any[],
		params?: HttpParams,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON,
		controller?: ServiceController,
		xFormatParams?: FormatParams[],
		header?: ServiceHeadersConfig): Observable<string> {

		const {searchParams, data} = this.searchParamsToQueryData(params);

		const tempValue = value ?? [];
		tempValue.push(QUERY_URL_PARAM);

		return this.post<string>(action, data, searchParams, tempValue, controller, header, responseBodyType, xFormatParams);
	}

	/**
	 * @todo FIX - request data not attaching!!!!
	 * Function to make an HTTP POST request
	 * @param action Contains the 'action' part of the request URL
	 * @param postData Object containing the data to be sent to the server
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @param controller provides a way to override the constructor set value whilst still being backward compatible.
	 * @param header provides a way to override the method set value whilst still being backward compatible. By default all POST request
	 * attaches authorisation token.
	 * @param responseBodyType one of JSON | Text | Blob; by convention the default is usually JSON.
	 * @param xFormatParams placed here for backward compatible
	 * @returns An Observable of the data returned from the server
	 */
	protected post<T>(
		action?: ServiceAction,
		postData?: any,
		searchParams?: HttpParams,
		value?: any[], controller?: ServiceController,
		header?: ServiceHeadersConfig,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON,
		xFormatParams?: FormatParams[]
	): Observable<any> {
		const ctrl = controller ?? this.controller;
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, ctrl, action, value);

		const headerConfig = header ? header : this.headersConfig;

		const body = ServiceRequestBodyUtility.getRequestBody(postData, headerConfig.contentType);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(
			xFormatParams ? headerConfig.attachXFormatParams(xFormatParams) : headerConfig);

		const options: any = {
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		options.responseType = this.setResponseType(responseBodyType);

		return this.http.post<T>(serviceUrl, body, options);
	}

	/**
	 * Function to make an HTTP PUT request
	 * @param action Contains the 'action' part of the request URL
	 * @param editData Object containing the data to be sent to the server
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @param controller provides a way to override the constructor set value whilst still being backward compatible.
	 * @returns An Observable of the data returned from the server
	 */
	protected edit<T>(
		action?: ServiceAction, editData?: any, searchParams?: HttpParams, value?: any[], controller?: ServiceController
	): Observable<any> {
		const ctrl = controller ?? this.controller;
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, ctrl, action, value ?? []);

		// Note: If method fails as a result of body/header - for FormData - check pattern from POST for fix
		const body = JSON.stringify(editData);
		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		return this.http.put<T>(serviceUrl, body, options)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * @todo Make sure the structure of PUT returned from BE is in the expected format for all PUT
	 *       - And in future {@link edit} link can be refactor as a private helper and all callers are to go through {@link editWithValidate}
	 */
	protected editWithValidate<D>(
		requiredFields: string[],
		action?: ServiceAction, editData?: any, searchParams?: HttpParams, value?: any[],
		controller?: ServiceController): Observable<D> {
		return new Observable<D>(subscriber => {
			// get(ServiceAction.AGENTS_OPERATOR_CURRENCIES, [String(tenantID)], undefined, undefined, ServiceController.USER_OWN_CONTROLLER)
			this.edit<D>(action, editData, searchParams, value, controller).subscribe({
				next: res => {
					let hasRequiredFields: boolean;

					// const obj of Object.keys(res)
					for (const field of requiredFields) {
						hasRequiredFields = true;

						hasRequiredFields = hasRequiredFields && res.hasOwnProperty(field);

						if (!hasRequiredFields) {
							console.error(`#validatePutRes: some, or all, required fields ${requiredFields} does not exist on obj.`);
							break;
						}
					}

					// Use flag rather than "res.resultSet > 0" so that genuine empties are not classed as error
					if (hasRequiredFields) {
						subscriber.next(res);
					} else {
						subscriber.error(API_DATA_INVALID);
					}

					subscriber.complete();
				},
				error: err => {
					subscriber.error(err);
				}
			})
		});
	}

	/**
	 * Function to make an HTTP PATCH request
	 * @param action Contains the 'action' part of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @param data Object containing the data to be sent to the server
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @param controller provides a way to override the constructor set value whilst still being backward compatible.
	 * @returns An Observable of the data returned from the server
	 */
	protected patch<T>(
		action?: ServiceAction,
		data?: any | any[],
		searchParams?: HttpParams,
		value?: any[],
		controller?: ServiceController
	): Observable<any> {
		const ctrl = controller ?? this.controller;
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, ctrl, action, value ?? []);

		const body = JSON.stringify(data);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		return this.http.patch<T>(serviceUrl, body, options);
	}

	/**
	 * Function to make an HTTP DELETE request
	 * @param action Contains the 'action' part of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @returns An Observable of boolean signifying whether delete was successful or not
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @param controller provides a way to override the constructor set value whilst still being backward compatible.
	 */
	protected delete<T>(
		action?: ServiceAction,
		value?: any[],
		searchParams?: HttpParams,
		controller?: ServiceController
	): Observable<any> {
		const ctrl = controller ?? this.controller;
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, ctrl, action, value ?? []);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		return this.http.delete<T>(serviceUrl, options)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * Function to make an HTTP DELETE request
	 * @param action Contains the 'action' part of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @returns An Observable of any signifying whether delete was successful or not and any data it might return
	 */
	protected deleteWithReturn<T>(action?: ServiceAction, value?: any[]): Observable<any> {
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, this.controller, action, value);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			headers: headers
		};

		return this.http.delete<T>(serviceUrl, options)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * Function called to handle unexpected errors
	 */
	protected handleError(serviceErrors: HttpErrorResponse) {
		let errors: ServiceError[] = serviceErrors.error as ServiceError[];
		if (errors && Array.isArray(errors)) { // To guard against times when the cast to ServiceError[] is unsuccessful and is obj
			errors.forEach((item) => {
				item.status = serviceErrors.status;
			});
		} else {
			errors = [];
			errors.push(new ServiceError(serviceErrors.status, serviceErrors.statusText, 0, undefined));
		}

		return _throw(errors);
	}

	protected searchParamsToQueryData(params?: HttpParams, useDollarParams: boolean = true): { searchParams: HttpParams, data: GetQueryData } {
		const data: GetQueryData = new GetQueryData();

		if (!params) {
			return {searchParams: params, data};
		}

		const takeLabel = useDollarParams ? TAKE_URL_PARAM : TAKE_URL_PARAM_DOLLAR_FREE
		const offsetLabel = useDollarParams ? OFFSET_URL_PARAM : OFFSET_URL_PARAM_DOLLAR_FREE

		let searchParams: HttpParams = params.delete('doesNotExist'); // to get a clone copy of params

		if (searchParams.has(takeLabel)) {
			data.take = Number(searchParams.get(takeLabel));
			searchParams = searchParams.delete(takeLabel);
		}

		if (searchParams.has(offsetLabel)) {
			data.offset = Number(searchParams.get(offsetLabel));
			searchParams = searchParams.delete(offsetLabel);
		}

		if (searchParams.has(ORDER_BY_URL_PARAM)) {
			data.orderBy = searchParams.get(ORDER_BY_URL_PARAM);
			searchParams = searchParams.delete(ORDER_BY_URL_PARAM);
		}

		if (searchParams.has(FILTER_URL_PARAM)) {
			data.filter = searchParams.get(FILTER_URL_PARAM);
			searchParams = searchParams.delete(FILTER_URL_PARAM);
		}

		if (searchParams.has(FORMAT_URL_PARAM)) {
			data.format = searchParams.get(FORMAT_URL_PARAM);
			searchParams = searchParams.delete(FORMAT_URL_PARAM);
		}

		return {searchParams, data};
	}

	// protected abstract mapData(data: any): any;
	/**
	 * @todo the use of {@link requiredFields} does not support DRY as these values will also require modifying should
	 *   the object signatures change. A safe solution would be to reflect the fields of the Generic dto!!
	 */
	protected validateDataTableRes<D>(
		requiredFields: string[],
		action?: ServiceAction,
		value?: any[],
		params?: HttpParams,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON,
		controller?: ServiceController,
		header?: ServiceHeadersConfig): Observable<TableDataResponse<D>> {

		const {searchParams, data} = this.searchParamsToQueryData(params);

		const tempValue = value ?? [];
		tempValue.push(QUERY_URL_PARAM);

		return new Observable<TableDataResponse<D>>(subscriber => {
			// get(ServiceAction.AGENTS_OPERATOR_CURRENCIES, [String(tenantID)], undefined, undefined, ServiceController.USER_OWN_CONTROLLER)
			this.post<TableDataResponse<D>>(action, data, searchParams, tempValue, controller, header, responseBodyType).subscribe({
				next: res => {
					let isValidData = true;
					let hasRequiredFields: boolean;
					const tempData: D[] = []

					for (const obj of (res?.resultSet as D[])) {
						hasRequiredFields = true;

						for (const field of requiredFields) {
							hasRequiredFields = hasRequiredFields && obj.hasOwnProperty(field);

							if (!hasRequiredFields) {
								// @ts-ignore
								console.error(`#getAndValidateDataTableRes: some or all of the required fields ${requiredFields}
								 does not exist on object.`)
								break;
							}
						}

						// Validate, to prevent adding null or undefined lookup
						if (hasRequiredFields) {
							tempData.push(obj as D);
						} else {
							isValidData = false;
							break;
						}
					}

					// Use flag rather than "res.resultSet > 0" so that genuine empties are not classed as error
					if (isValidData) {
						res.resultSet = tempData;
						subscriber.next(res);
					} else {
						subscriber.error(API_DATA_INVALID);
					}

					subscriber.complete();
				},
				error: err => {
					subscriber.error(err);
				}
			})
		});
	}

	protected validateAndParseLookupResSTD<U>(
		lookupArr: U[],
		validatingFields: { label: string, value: string },
		action?: ServiceAction,
		value?: any[],
		searchParams?: HttpParams,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON,
		controller?: ServiceController
	): Observable<U[]> {
		if (isDefinedArray(lookupArr)) {
			return of(lookupArr);
		}

		return new Observable<U[]>(subscriber => {
			// TODO - Provide a better solution to the below comment (i.e. a way to flag to UI that lookup is not available for e2e test)
			// First deliver the undefined value, indicating that lookup is not ready, so caller can show Toast
			// subscriber.next(lookupArr);

			// TODO: Use named params for #get method - make sure it's backwards compatible
			this.get(action, value, searchParams, responseBodyType, controller).subscribe({
				next: res => {
					lookupArr = [];

					let isNonconformingRes = false;

					const list = res?.resultSet ?? res;

					list.forEach(obj => {
						// Validate, to present adding null or undefined lookup
						if (obj.hasOwnProperty(validatingFields.label) && obj.hasOwnProperty(validatingFields.value)) {
							lookupArr.push({
								...obj,
								label: obj[validatingFields.label],
								value: obj[validatingFields.value]
							} as U);
						} else {
							isNonconformingRes = true;
						}
					});

					if (isNonconformingRes) {
						// Rather than throwing an exception, simply log as the app should not fail because of absence of lookup
						console.error(`#validateAndParseLookupResSTD: one or more object delivered - by ${action} - has been omitted for missing a required field.`);
					}

					if (lookupArr) {
						subscriber.next(JSON.parse(JSON.stringify(lookupArr)));
					} else {
						subscriber.error(new Error(API_DATA_INVALID));
					}

					subscriber.complete();
				},
				error: err => {
					subscriber.error(err);
				}
			})
		})
	}

	protected validateAndParseLookupResQUERY<U>(
		lookupArr: U[],
		validatingFields?: { label: string, value: string },
		action?: ServiceAction,
		value?: any[],
		params?: HttpParams,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON,
		controller?: ServiceController,
		header?: ServiceHeadersConfig
	): Observable<U[]> {
		if (isDefinedArray(lookupArr)) {
			return of(lookupArr);
		}

		return new Observable<U[]>(subscriber => {
			// TODO - Provide a better solution to the below comment (i.e. a way to flag to UI that lookup is not available for e2e test)
			// First deliver the undefined value, indicating that lookup is not ready, so caller can show Toast
			// subscriber.next(lookupArr);

			const tempValue = value ?? [];
			tempValue.push(QUERY_URL_PARAM);

			let tempParams = new HttpParams();

			if (params) {
				tempParams = params

				if (!tempParams.has(TAKE_URL_PARAM)) {
					tempParams = tempParams.set(TAKE_URL_PARAM, 1000)
				}

				if (!tempParams.has(OFFSET_URL_PARAM)) {
					tempParams = tempParams.set(OFFSET_URL_PARAM, 0)
				}
			} else {
				tempParams = tempParams.set(TAKE_URL_PARAM, 1000)
					.set(OFFSET_URL_PARAM, 0)
			}

			const {searchParams, data} = this.searchParamsToQueryData(tempParams);

			// TODO: Use named params for #get method - make sure it's backwards compatible
			this.post(action, data, searchParams, tempValue, controller, header, responseBodyType).subscribe({
				next: res => {
					lookupArr = [];

					let isNonconformingRes = false;

					if (validatingFields) {
						const list = res?.resultSet ?? res;

						list.forEach(obj => {
							// Validate, to present adding null or undefined lookup
							if (obj.hasOwnProperty(validatingFields.label) && obj.hasOwnProperty(validatingFields.value)) {
								lookupArr.push({
									...obj,
									label: obj[validatingFields.label],
									value: obj[validatingFields.value]
								} as U);
							} else {
								isNonconformingRes = true;
							}
						});

						if (isNonconformingRes) {
							// Rather than throwing an exception, simply log as the app should not fail because of absence of lookup
							console.error(`#validateAndParseLookupResSTD: one or more object delivered - by ${action} - has been omitted for missing a required field.`);
						}
					} else {
						lookupArr = res?.resultSet ?? res;
					}

					if (lookupArr) {
						subscriber.next(JSON.parse(JSON.stringify(lookupArr)));
					} else {
						subscriber.error(new Error(API_DATA_INVALID));
					}

					subscriber.complete();
				},
				error: err => {
					subscriber.error(err);
				}
			})
		})
	}

	protected validateAndParseObject<U>(
		validatingFields: string[],
		action?: ServiceAction,
		value?: any[],
		searchParams?: HttpParams,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON,
		controller?: ServiceController
	): Observable<U> {
		return new Observable<U>(subscriber => {
			// TODO - Provide a better solution to the below comment (i.e. a way to flag to UI that lookup is not available for e2e test)
			// First deliver the undefined value, indicating that lookup is not ready, so caller can show Toast
			// subscriber.next(lookupArr);

			// TODO: Use named params for #get method - make sure it's backwards compatible
			this.get(action, value, searchParams, responseBodyType, controller).subscribe({
				next: obj => {
					let isConformingRes = true;

					for (const field of validatingFields) {
						if (!obj.hasOwnProperty(field)) {
							isConformingRes = false;
							break;
						}
					}

					if (isConformingRes) {
						subscriber.next(obj);
					} else {
						console.warn(`#validateAndParseObject: one or more fields delivered - by ${action} - is missing.`);
						subscriber.error(new Error(API_DATA_INVALID));
					}

					subscriber.complete();
				},
				error: err => {
					subscriber.error(err);
				}
			})
		})
	}

	/**
	 * Function to set responseType
	 */
	private setResponseType(responseBodyType: ResponseBodyType): string {
		switch (responseBodyType) {
			case ResponseBodyType.Text:
				return 'text';
			case ResponseBodyType.Blob:
				return 'blob';
			case ResponseBodyType.JSON:
			default:
				return 'json';
		}
	}
}
