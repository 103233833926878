<div class="advanced-search-wrapper">
	<he-advanced-search #advancedSearchComponent *ngIf="searchFields !== undefined && showAdvancedSearch"
						[searchFields]="searchFields"
						(searchEvent)="handleAdvancedSearch($event)"></he-advanced-search>
</div>

<!--
	1. [lazy]="lazy" is necessary for serverside pagination, however, PrimeNG
	   initiates multiple callbacks on launch, which causes the same data to be re-fetched, thereby placing UI
	   in a prolonged loading state even though data is ready.
	?? [scrollHeight]="scrollContainerHeightPX" [virtualScrollItemSize]="virtualScrollItemSizePX"-->
<ng-container *ngIf="headerTitle === undefined || headerTitle">
	<!--Always display standalone title if it is already defined-->
	<ng-container *ngIf="headerTitle === undefined && loading; else standalone_header">
		<p-skeleton [style]="{'margin-top': '1em', 'margin-bottom': '1em'}" width="180px"
					height="33px"></p-skeleton>
	</ng-container>

	<ng-template #standalone_header>
		<he-header [headerTitle]="headerTitle"></he-header>
	</ng-template>
</ng-container>

<!--<ng-container *ngIf="isLoading">
	<he-skeleton-table [skeletonOverheadOptRight]="skeletonOverheadOptRight"
					   [skeletonOverheadOptLeft]="skeletonOverheadOptLeft"
					   [skeletonOverheadOptCount]="skeletonOverheadItemCount"
					   [skeletonHasHeader]="skeletonHasHeader" [skeletonHasFooter]="skeletonHasFooter"
					   [isTabbedTable]="isTabbedTable"></he-skeleton-table>
</ng-container>-->

<p-table #myTableV3
		 [columns]="columns" [value]="data" [dataKey]="dataKey"
		 [lazy]="lazy" (onLazyLoad)="loadLazyDataTable($event)" lazyLoadOnInit="false"
		 [paginator]="true" [totalRecords]="totalRecords" [rows]="rowsPerPage"

		 [resizableColumns]="resizableColumns" [columnResizeMode]="columnResizeMode" [loading]="loading"

		 [(selection)]="selectedRows" [selectionMode]="isBulkMode ? 'multiple' : undefined"
		 [(first)]="firsPage" styleClass="p-datatable-striped"
		 (onRowSelect)="onRowSelectChange($event, true)" (onRowUnselect)="onRowSelectChange($event, false)"
		 (onHeaderCheckboxToggle)="onRowSelectChange($event, false)"

		 (onRowReorder)="handleOnRowReorder($event)"

		 [sortField]="orderByRestored" [sortOrder]="orderDirectionRestored">

	<!--<ng-template *ngIf="scrollable" pTemplate="colgroup" let-columns>
		<colgroup>
			<col *ngFor="let col of columns">
		</colgroup>
	</ng-template>-->

	<!--Table's overhead options (e.g: number of rows per page, Bulk Operations, Advanced Search, etc.-->
	<ng-template *ngIf="showHeader" pTemplate="caption">
		<div class="tabbed-table-overhead-opts tabbed-table-placement"
			 [ngStyle]="{display: isTabbedTable || (showNestedOverheadOpts && customRef.children.length > 0) ? 'grid' : 'none'}">
			<span class="tabbed-title" [class.hide-tabbed-title]="!isTabbedTable"
				  style="margin-right: 36px;">{{ tabbedTitle }}</span>

			<div class="overhead-opts" #customRef [ngStyle]="{display: customRef.children.length > 0 ? 'grid' : 'none'}">
				<ng-content></ng-content>
			</div>
		</div>

		<div id="overhead-opts-wrapper">
			<p-dropdown [options]="rowsPerPageOpts" [ngModel]="rowsPerPage" class="rows-to-display"
						(ngModelChange)="rowsToDisplayChange($event)"></p-dropdown>

			<span id="spacer"></span>

			<div id="dyn-opts">
				<div style="display: flex; column-gap: 8px;">
					<ng-container *ngIf="rowsPerPage > 0 && canExportAll && showExportAll">
						<!--label="Export All to CSV"-->
						<button type="button" pButton title="Export All to CSV" class="helio-orange-btn"
								icon="ui-icon-insert-drive-file" (click)="exportAllToCsv()"></button>
					</ng-container>

					<div *ngIf="hasBulkActions" class="bulk-operations">
						<!--label="Bulk Operations"-->
						<button type="button" pButton title="Bulk Operations" class="helio-orange-btn"
								icon="ui-icon-settings" style="margin-left: 6px" (click)="bulkActionsClick()"
								data-cy="bulkOpsBtn"></button>

						<ng-container *ngIf="isBulkMode">
							<ng-container *ngFor="let bulkAction of bulkActionsMenu">
								<button class="helio-orange-btn" type="button" pButton [label]="bulkAction.label"
										[icon]="bulkAction.icon" [attr.he-label]="bulkAction.label"
										(click)="bulkAction.command($event)"
										[disabled]="selectedRows.length === 0 || (bulkAction.enablePredicate && !bulkAction.enablePredicate())"></button>
							</ng-container>
						</ng-container>
					</div>
				</div>

				<div *ngIf="hasTableActions" class="table-operations">
					<ng-container *ngFor="let tableAction of tableActionsMenu">
						<!--[label]="tableAction.label"-->
						<button class="helio-orange-btn" type="button" pButton [title]="tableAction.label"
								[icon]="tableAction.icon" [attr.he-label]="tableAction.label"
								(click)="tableAction.command($event)"></button>
					</ng-container>
				</div>

				<ng-container *ngIf="searchFields !== undefined && searchFields.length > 0">
					<!--label="Advanced Search"-->
					<button type="button" pButton class="helio-orange-btn"
							title="Advanced Search" icon="ui-icon-search" (click)="showAdvancedSearchClick()"
							[attr.he-label]="'Advanced Search'" data-cy="advancedSearchBtn"></button>
				</ng-container>
			</div>
		</div>
	</ng-template>

	<!-- Table's Column header -->
	<ng-template pTemplate="header">
		<tr>
			<th *ngIf="reorderableRows" class="reorder-col"></th>

			<th *ngIf="(hasBulkActions && isBulkMode) || (selectionCheckbox === checkboxColumnPos.FirstCol)"
				class="checkbox-col">
				<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
			</th>

			<th *ngIf="expandableRows" class="expand-row-col"></th>

			<th *ngFor="let col of columns" [pSortableColumn]="col.field"
				[pSortableColumnDisabled]="col.sortable === false" pResizableColumn>
				{{ col.header }}
				<p-sortIcon *ngIf="col.sortable !== false" [field]="col.field"></p-sortIcon>
			</th>

			<th *ngIf="rowActionsMenu !== undefined && rowActionsMenu.length > 0" class="actions-col">&nbsp;</th>

			<th *ngIf="(selectionCheckbox === checkboxColumnPos.LastCol)" class="checkbox-col">
				<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
			</th>
		</tr>
	</ng-template>

	<!-- Table body

	Don't set [style.height.px]="virtualScrollItemSizePX" on <tr> since the height of <tr> is being used in code
	as the source of truth for virtualScrollItemSizePX, which is in turn set on p-table.-->
	<ng-template pTemplate="body" let-data let-expanded="expanded" let-index="rowIndex">
		<tr [pSelectableRow]="data" [pReorderableRow]="index">
			<td *ngIf="reorderableRows">
				<span class="pi pi-bars" style="color: #495057;" pReorderableRowHandle></span>
			</td>

			<td *ngIf="(hasBulkActions && isBulkMode) || (selectionCheckbox === checkboxColumnPos.FirstCol)"
				class="table-row-checkbox" style="padding: .714em 1.2em;">
				<p-tableCheckbox [value]="data" [pSelectableRow]="data"></p-tableCheckbox>
			</td>

			<td *ngIf="expandableRows" class="expand-row-cell">
				<button type="button" pButton pRipple [pRowToggler]="data"
						class="p-button-text p-button-rounded p-button-plain"
						[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
			</td>

			<ng-container *ngFor="let col of columns">
				<!-- class="dataEntry" is used to differentiate data columns from the checkbox for the purpose of
				CSS styling in mobile and table wide points-->

				<!-- When setRwdHeaders=true (and then inverted to false), pSortableColumn is activated via p-table
					 such that clicking on the <td>, which is normally on just a cell in table (i.e., desktop mode),
					 invokes sorting of the cards (aka tr elements) in responsive mode.

					 By default setRwdHeaders=false which means that this feature is deactivated. -->

				<!-- *************************** In the code that follows: ngTemplateOutlet in combo with *ngIf
				  used to simulate if - else if - else, whilst striving for DRY *************************** -->

				<ng-container [ngTemplateOutlet]="(col.editable) ? columnEditable : notEditable">
				</ng-container>

				<ng-template #notEditable>
					<!-- UN-EDITABLE columns -->
					<ng-container *ngIf="!col.editable" [ngTemplateOutlet]="notEditableDefault">
					</ng-container>
				</ng-template>

				<ng-template #columnEditable>
					<!-- Editable columns -->
					<ng-container *ngIf="col.editable && col?.editableRowIndex === undefined; else rowEditable"
								  [ngTemplateOutlet]="editableDefault">
					</ng-container>
				</ng-template>

				<ng-template #rowEditable>
					<!-- Editable rows -->
					<ng-container *ngIf="index === col?.editableRowIndex; else notEditableDefault"
								  [ngTemplateOutlet]="editableDefault">
					</ng-container>
				</ng-template>

				<ng-template #editableDefault>
					<!-- Default for Editable columns and rows-->
					<td pEditableColumn
						style="overflow: visible;" class="dataEntry">
					<span [pSortableColumn]="col.field" [pSortableColumnDisabled]="!setRwdHeaders">
						<strong><span
							[style.display]="setRwdHeaders ? 'inline-block' : 'none'">{{ col.header + ': ' }}</span></strong>
						<p-sortIcon
							[style.display]="((col.sortable !== false) && setRwdHeaders) ? 'inline-block' : 'none'"
							[field]="col.field"
							class="rwd-sort-icon"></p-sortIcon>
					</span>

						<p-cellEditor
							style="max-width: 100%; display: inline-block; width: 100%; border: solid 1px #495057;border-radius: 4px;padding: 8px;">
							<ng-template
								*ngIf="col.columnType !== undefined && col.columnType === listForInlineEditColumnType"
								pTemplate="input">
								<p-dropdown [options]="col.options" [(ngModel)]="data[col.databindingField]"
											filter="true"
											[appendTo]="'body'"></p-dropdown>
							</ng-template>

							<ng-template *ngIf="col.columnType !== undefined && col.columnType === dateColumnType"
										 pTemplate="input">
								<p-calendar [(ngModel)]="data[col.field]" [showIcon]="true" dateFormat="dd/mm/yy"
											readonlyInput="readonlyInput" [appendTo]="'body'"></p-calendar>
							</ng-template>

							<ng-template *ngIf="col.columnType === MyColumnType.Number" pTemplate="input">
								<p-inputNumber class="editable-number" mode="decimal" [minFractionDigits]="0"
											   min="0" [ngModel]="(data | heColData:col.field)"
											   [pKeyFilter]="getKeyFilter(col.columnType)"
											   (ngModelChange)="cellEditModelChange($event, data, col.field)"
											   (change)="onCellEditComplete(data)"
											   [showButtons]="true"></p-inputNumber>
							</ng-template>

							<ng-template
								*ngIf="col.columnType !== listForInlineEditColumnType && col.columnType !== dateColumnType && col.columnType !== MyColumnType.Number"
								pTemplate="input">
								<input type="text" (change)="onCellEditComplete(data)" pInputText style="width: 100%"
									   [pKeyFilter]="getKeyFilter(col.columnType)"
									   [ngModel]="(data | heColData:col.field)"
									   (ngModelChange)="cellEditModelChange($event, data, col.field)"
									   required>
							</ng-template>

							<ng-template pTemplate="output">
								<he-column-data [column]="col" [data]="data"></he-column-data>
							</ng-template>
						</p-cellEditor>
					</td>
				</ng-template>

				<ng-template #notEditableDefault>
					<!-- UN-EDITABLE columns DEFAULT - without any condition too, so that rows that aren't
					applicable when in rowEditable, can be rendered as such-->
					<td [ngClass]="['dataEntry', col.field]">
						<!-- Isolating pSortableColumn to ensures that data within he-column-data is not clickable -->
						<span [pSortableColumn]="col.field" [pSortableColumnDisabled]="!setRwdHeaders">
							<strong><span
								[style.display]="setRwdHeaders ? 'inline-block' : 'none'">{{ col.header + ': ' }}
							</span></strong>

							<p-sortIcon
								[style.display]="((col.sortable !== false) && setRwdHeaders) ? 'inline-block' : 'none'"
								[field]="col.field" class="rwd-sort-icon"></p-sortIcon>
						</span>

						<!--{{ data[col.field] }}-->
						<!--heReplaceInvalidWith="N/A"-->
						<!-- Use parsed field for localised fields such as number since this would otherwise
						show the raw db version-->
						<he-column-data [column]="col" [data]="data" #columnData
										[title]="col.localeDecimalPlaces ? getTitle(columnData) : transformColData(data, col.field)"></he-column-data>
					</td>
				</ng-template>
			</ng-container>

			<td *ngIf="rowActionsMenu !== undefined && rowActionsMenu.length > 0" class="actions-col">
				<p-menu #menu popup="popup" [model]="rowActionsMenu" appendTo="body"></p-menu>

				<button type="button" pButton class="helio-orange-btn" icon="ui-icon-settings"
						(click)="menu.toggle($event); rowActionsClick(data);"
						[disabled]="selectedRows.length > 0"></button>
			</td>

			<td *ngIf="selectionCheckbox === checkboxColumnPos.LastCol">
				<p-tableCheckbox [value]="data"></p-tableCheckbox>
			</td>
		</tr>
	</ng-template>

	<ng-template *ngIf="expandableRows" pTemplate="rowexpansion" let-data let-expanded="expanded" let-index="rowIndex">
		<tr class="expansionTemplateTR">
			<td [attr.colspan]="rowColspan">
				<div class="expansionTemplateContainer">
					<ng-container
						*ngTemplateOutlet="rowTemplate; context: {$implicit: data, index: index}"></ng-container>
				</div>
			</td>
		</tr>
	</ng-template>

	<ng-template pTemplate="emptymessage">
		<tr>
			<td [attr.colspan]="rowColspan">
				{{ tableMessage }}
			</td>
		</tr>
	</ng-template>
</p-table>
