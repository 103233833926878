import {Subscription} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {DataTableLazyLoadEvent} from '../components';
import {SearchEventType} from '../components/data-table-v3/advanced-search/submit-search-event.model';
import {FILTER_URL_PARAM, OFFSET_URL_PARAM, ORDER_BY_URL_PARAM, TAKE_URL_PARAM} from '../constants';
import {LogService} from '../services/log.service';

export abstract class ComponentHelper {
	params: HttpParams;

	releaseSubscriptions(...subs: Subscription[]) {
		for (let s of subs) {
			if (s) {
				s.unsubscribe();
				s = undefined;
			}
		}
	}

	joinRequestFilters(httpParams: HttpParams, defaultFilters: string) {
		const params: HttpParams = httpParams ?? new HttpParams();

		// Check if $filter already exists in httpParams
		const currentFilter = params.get('$filter');

		if (currentFilter) {
			// If defaultFilters is already contained in $filter, do nothing
			if (currentFilter.includes(defaultFilters)) {
				return params;
			} else {
				// Otherwise, wrap the original $filter value and defaultFilters in parentheses and join them with AND
				const updatedFilter = `${currentFilter} AND ${defaultFilters}`; // `(${currentFilter}) AND (${defaultFilters})`;
				return params.set('$filter', updatedFilter);
			}
		} else {
			if (defaultFilters && defaultFilters.length > 0) {
				// If $filter doesn't exist, set it with the value of defaultFilters if it is defined
				return params.set('$filter', defaultFilters);
			} else {
				return params
			}
		}
	}

	/**
	 * Call before call to {@link getTableData} to compute querying HttpParams.
	 *
	 * @todo in the future, when calling super.getTableData is enforced, this call can be made
	 *   automatically within this class in {@link #getTableData}
	 * @param event Event generated by table
	 * @param filters default filters to use, if they aren't in conflict to those contained in event object
	 * @param take a way to align with table default rowsPerPage
	 * @example filter = gameType eq "SimpleLotto" & gameFilter eq 0
	 *
	 * @note housed here for ease of testing, as no dependency injection required!
	 */
	public computeTableDataHttpParams(
		event?: DataTableLazyLoadEvent,
		filters?: string,
		take?: number,
		orderBy?: string
	): HttpParams {
		let params = new HttpParams();

		let rowsPerPage: string;

		// Use the currently selected numOfRows per page (as per the UI) for ASearch filter
		if (event?.take) {
			rowsPerPage = String(event.take)
		} else {
			rowsPerPage = take ? String(take) : '10';
		}

		params = params.set(TAKE_URL_PARAM, rowsPerPage);

		const offset = event?.primeNGEvent?.first ?
			event?.primeNGEvent?.first : event?.offset ?
				event?.offset : '0';
		params = params.set(OFFSET_URL_PARAM, offset);

		if (event?.advancedSearchData?.searchType === SearchEventType.CLOSE ||
			event?.advancedSearchData?.searchType === SearchEventType.CLEAR) {

			// Placing this before event?.urlParams is important since otherwise it can be missed
			params = params.set(OFFSET_URL_PARAM, (event as any)?.first ?? '0');
		} else if (event?.advancedSearchData?.searchType === SearchEventType.SUBMIT) {
			// Placing this before event?.urlParams is important since otherwise it can be missed
			params = params.set(OFFSET_URL_PARAM, (event as any)?.first ?? '0');

			// Find the filterString and assign it to HttpParams
			try {
				// @ts-ignore
				const filterParamVal = (event?.advancedSearchData.urlParams.updates as any[])
					.find(entry => entry.param === FILTER_URL_PARAM)?.value

				params = this.joinRequestFilters(params, filterParamVal)
			} catch (e) {
				LogService.devError('computeTableDataHttpParams: error =', e)
			}
		} else if (event?.primeNGEvent) {
			// Prepare and handle PrimeNG (direct from table) sorting (aka desc or asc) and paging requests

			const sortOrder = (event.primeNGEvent.sortOrder === 1) ? 'asc' : 'desc';

			if (event.primeNGEvent.rows) {
				params = params.set(TAKE_URL_PARAM, event.primeNGEvent.rows);
			}

			if (event.primeNGEvent.first) {
				params = params.set(OFFSET_URL_PARAM, event.primeNGEvent.first);
			}

			if (event.primeNGEvent.sortField) {
				params = params.set(ORDER_BY_URL_PARAM, `${event.primeNGEvent.sortField} ${sortOrder}`);
			}
		}/* else if (event?.urlParams) {
			// Handle pre-prepared AdvanceSearch request
			hasEventOrderBy = event?.orderBy != null;
			eventFilters = event?.urlParams?.get(FILTER_URL_PARAM);

			params = event.urlParams;
		}*/ else {
			// event.first targets the signature of drawService#getActiveDraws event
			params = params.set(OFFSET_URL_PARAM, (event as any)?.first ?? '0');
		}

		// Check to see if default params are specified, if so attempt to join them to the request
		if (filters) {
			params = this.joinRequestFilters(params, filters)
		}

		if (!params.has(ORDER_BY_URL_PARAM) && orderBy) {
			params = params.set(ORDER_BY_URL_PARAM, orderBy);
		}

		return params;
	}
}
