import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {ChangeDrawDateData} from '../shared';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {Draw} from '../../shared/models';
import {BehaviorSubject} from 'rxjs';
import {AppConfigService} from '../../helio-core-services';

@Component({
	selector: 'he-change-draw-date',
	styleUrls: [
		'./change-draw-date.component.scss',
		'./../../shared/components/dialog/common-draw-dialog-style.scss'
	],
	templateUrl: './change-draw-date.component.html'
})
export class ChangeDrawDateComponent implements OnInit {
	/**
	 * @summary visibleChange, which would result in biding to visible is not used as most use cases do not use
	 * primitive, which is required for binding, but complex predicates as well as those derived from objects.
	 * @example [visible]="drawOpsImpl.displayApproveResultDialog && this.drawOpsImpl.lotteryResults != undefined"
	 *          (visibleEvent)="onApproveResultDialogVisChange($event)"
	 */
	@Output() visibleChange = new EventEmitter<boolean>();

	// @Output() closeEvent = new EventEmitter<void>();
	@Output() submitEvent = new EventEmitter<ChangeDrawDateData>();

	formGroup: FormGroup;
	private _draw$ = new BehaviorSubject<Draw>(null);
	_visible$ = new BehaviorSubject<boolean>(false);

	originalDrawDate: Date;
	dateFormat: string;
	timezoneOffset: string;

	constructor(
		private formBuilder: FormBuilder,
		private appConfigService: AppConfigService,
	) {
		this.dateFormat = this.appConfigService.defaultDateFormat ?? 'dd/MM/yyyy HH:mm:ss \'UTC\'';
		// offset is +0000 is important to attain UTC = GMT, i.e. Daylight Saving Time (DST) is not taken into account
		this.timezoneOffset = this.appConfigService.defaultTimezoneOffset ?? '+0000';
	}

	get draw() {
		return this._draw$.getValue();
	};

	@Input() set draw(value: Draw) {
		this._draw$.next(value);
	};

	get visible() {
		return this._visible$.getValue();
	};

	@Input() set visible(value: boolean) {
		this._visible$.next(value);
	};

	get isFormDisabled(): boolean {
		if (!this.formGroup) {
			return true;
		}

		return !this.formGroup.valid && (this.formGroup.dirty || this.formGroup.touched)
			|| !this.formGroup.touched;
	}

	ngOnInit() {
		this.formInit();

		// Enforcing that the value is set in this._visible$ rather than in this._draw$ subscribe is necessary to handle bug whereby the
		// value is unset when hidden in-between making requests.
		this._visible$.asObservable().subscribe({
			next: visible => {
				if (visible && this.formGroup && this.draw) {
					this.originalDrawDate = this.draw?.drawDate;
				}
			}
		})
	}

	onSubmit() {
		const request: ChangeDrawDateData = {
			drawID: this.draw.drawID,
			// userID: +sessionStorage.getItem('logged_in_userID'),
			drawDate: this.formGroup.get('newDrawDate').value,
			cancelReason: this.formGroup.get('reason').value
		};

		this.submitEvent.emit(request);

		this.formGroup.reset();
	}

	closeDialog() {
		// this.visible = false;
		this.visibleChange.emit(false);
		// this.closeEvent.emit();
	}

	private formInit(): void {
		this.originalDrawDate = this.draw?.drawDate;

		this.formGroup = this.formBuilder.group({
			newDrawDate: new FormControl('', Validators.required),
			reason: new FormControl('', {
				validators: [Validators.required, Validators.minLength(10), Validators.maxLength(5000)]
			})
		});
	}
}
