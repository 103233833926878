export class IndividualPlayerConstants {
	public static TAB_NAMES = Object.freeze({
		playerTrail: 'Player Trail',
		participationHistory: 'Participation History',
		rewardPoints: 'Reward Points',
		transactionalKyc: 'KYC Management',
		sessions: 'Sessions',
		playerDetails: 'Player Details',
		playerAuditTabbed: 'Player Audit',
		playerAuditGlobal: 'Players Audit',
		walletTransactions: 'Wallet Transactions',
		messages: 'Messages',
		accountRestrictions: 'Account Restrictions',
		subscriptions: 'Subscriptions',
		freeTickets: 'Free Tickets',
	});

	public static TAB_INDEX = Object.freeze({
		playerDetails_TAB_0: 0,
		playerTrail_TAB_1: 1,
		partHistory_TAB_2: 2,

		walletTrans_TAB_6: 6,
		subscriptions_TAB_7: 7,
		accountRestrictions_TAB_8: 8,

		/*rewardPoints: 'Reward Points',
		transactionalKyc: 'Transactional KYC',
		sessions: 'Sessions',
		playerAuditTabbed: 'Player Audit',
		playerAuditGlobal: 'Players Audit',
		freeTickets: 'Free Tickets',*/
	});

	get TAB_NAME() {
		return IndividualPlayerConstants.TAB_NAMES;
	}
}
