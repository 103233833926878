<he-base-dialog header="{{'Exchange House Details'}}" [(visible)]="visible" [isLoadingLookups]="false"
				[style]="{'max-height': '80%', height: '900px', width: '100%'}" (visibleChange)="onCloseDialog()"
				rejectBtnOnly="true" rejectLabel="Close" class="basic-dialog-table">

	<!--[selectedRows]="selectedCards"-->
	<he-data-table-v3 [dataKey]="dataKey" [columns]="cols" [data]="data"
					  (lazyLoad)="getTableData($event)" [totalRecords]="totalRecords"
					  [tableActions]="tableActions" [tableMessage]="tableMessage" [searchFields]="searchFields"
					  [loading]="loading">
		<div class="he-specific-table-actions">
			<button class="helio-orange-btn" pButton type="button" (click)="showCreateExchangeHouseDialog = true"
					[label]="'Add ExchangeHouse'" [title]="'Add Exchange House'" icon="pi pi-plus"
					[disabled]="loading"></button>
		</div>
	</he-data-table-v3>

</he-base-dialog>

<he-exchange-house-editor-dialog *ngIf="showCreateExchangeHouseDialog" [(visible)]="showCreateExchangeHouseDialog"
								 style="position: absolute" forCRUD="true" dialogTitle="Add Exchange House"
								 [submitLabel]="'Add'" [playerPartialData]="playerPartialData"
								 (closeEvent)="showCreateExchangeHouseDialog = false; selectedRow = null;"
								 (submitEvent)="onCreateExchangeHouseSubmit($event)"></he-exchange-house-editor-dialog>

<he-exchange-house-editor-dialog *ngIf="showEditExchangeHouseDialog" [(visible)]="showEditExchangeHouseDialog"
								 [exchangeHouse]="selectedRow" [submitLabel]="'Edit'"
								 style="position: absolute" forCRUD="true" dialogTitle="Edit Exchange House"
								 (closeEvent)="showEditExchangeHouseDialog = false; selectedRow = null;"
								 (submitEvent)="onEditExchangeHouseSubmit($event)"></he-exchange-house-editor-dialog>
