<!--[responsive]="true" showEffect="fade" , -->
<he-base-dialog [header]="dialogTitle" [(visible)]="displayDialog" [isLoadingLookups]="isLoadingLookups"
				[style]="{'max-width': '700px', width: '640px'}" (visibleChange)="closeDialog()"
				[submitLabel]="'Create'" (submitEvent)="onSaveClicked()" [disableSubmit]="isRetailAgentFormValid"
				skeletonInputCount="3">
	<form [formGroup]="formGroup" (ngSubmit)="onSaveClicked()" class="dialog-form-placement">
		<label>{{ "Agent Type" }}</label>
		<p-dropdown formControlName="agentType" [options]="agentTypeOpts" placeholder="Please Select Agent Type"
					(onChange)="onAgentTypeSelected($event)" appendTo="body">
		</p-dropdown>

		<ng-container *ngIf="!isEdit">
			<label>{{ operatorLabel }}</label>
			<p-dropdown formControlName="tenant" [options]="tenantOpts" placeholder="Please Select Operator"
						(onChange)="onTenantSelected()" appendTo="body">
			</p-dropdown>
		</ng-container>

		<ng-container *ngIf="!isEdit && isTypeAdvance">
			<label>{{ brandLabel }}</label>
			<!--It is necessary to use he-disabled in template, in tandem with setting, disabled
			 programmatically in code, so that they do not conflict with each other. Put simply the predicates are
			 different, and setting both for p-disabled will simply cancel each other out and produce unexpected res-->
			<p-dropdown formControlName="brand"
						[class.he-disabled]="disableOperatorDependency || currencyOpts.length === 0"
						[placeholder]="selectOperatorPrompt" [options]="brandOpts"
						(onChange)="onBrandSelected($event)" appendTo="body"></p-dropdown>
		</ng-container>

		<ng-container *ngIf="isEdit && isTypeAdvance">
			<label>Currency</label>
			<div>
				<span class="disabled-currency">{{ retailAgentRequest.currencyID }}</span>
			</div>
		</ng-container>

		<ng-container *ngIf="!isEdit && isTypeAdvance">
			<label>Currency</label>
			<p-dropdown formControlName="currencyID"
						[class.he-disabled]="disableOperatorDependency || currencyOpts.length === 0"
						[placeholder]="selectOperatorPrompt" [options]="currencyOpts" appendTo="body"></p-dropdown>
		</ng-container>

		<label>Full Name</label>
		<input formControlName="fullName" pInputText type="text" placeholder="Full Name"/>

		<ng-container *ngIf="isTypeAdvance">
			<label>Username</label>
			<div>
				<input formControlName="username" pInputText type="text" placeholder="Username" autocomplete="off"/>
				<he-form-messages [errors]="formGroup?.controls?.username?.errors"
								  [reaction]="formGroup?.controls?.username?.dirty"></he-form-messages>
			</div>
		</ng-container>

		<ng-container *ngIf="!isEdit && isTypeAdvance">
			<label>Password</label>
			<div style="display: block; position: relative;">
				<input heTogglePasswordVisibility formControlName="password" pInputText
					   type="password" placeholder="Password" autocomplete="new-password"/>
				<he-form-messages [errors]="formGroup.controls.password.errors"
								  [reaction]="formGroup.controls.password.dirty"></he-form-messages>
			</div>
		</ng-container>

		<ng-container *ngIf="isTypeAdvance">
			<label>Email</label>
			<input formControlName="email" pInputText type="text" placeholder="Email" autocomplete="off"/>
		</ng-container>

		<ng-container *ngIf="isTypeAdvance">
			<label for="address">Address</label>
			<textarea id="address" formControlName="address" pInputTextarea type="text" rows="5" cols="30"
					  placeholder="Address"></textarea>
		</ng-container>

		<ng-container *ngIf="isTypeAdvance">
			<label>Active</label>
			<p-checkbox formControlName="isActive" binary="true"></p-checkbox>
		</ng-container>

		<ng-container *ngIf="!isEdit && isTypeAdvance">
			<label>Require Password Change</label>
			<p-checkbox formControlName="requirePasswordChange" binary="true"></p-checkbox>
		</ng-container>

		<ng-container *ngIf="isTypeAdvance">
			<!--This is the same as PlayerType column of PLAYER_TYPE.UI -->
			<label>{{ 'Allowed Player Interactions' }}</label>

			<p-multiSelect formControlName="playerType" [options]="playerTypesOpts"
						   placeholder="Please Select PlayerType" [style]="{'width': '100%'}"
						   appendTo="body"></p-multiSelect>
		</ng-container>
	</form>
</he-base-dialog>
