<div *ngIf="!isTabbedTable" class="header-opts">
	<he-header headerTitle="{{tabbedTitle}}"></he-header>

	<he-target-data-selector [loading]="loading" [(selectedItem)]="selectedTargetData" [options]="walletTypeLookup"
							 [placeholder]="'Please select a wallet type'"
							 (selectedItemChange)="onSelectedWalletTypeChange()"></he-target-data-selector>
</div>

<he-data-table-v3 [dataKey]="dataKey" [columns]="cols" [data]="data" [selectedRows]="selectedTransactions"
				  [lazy]="true" (lazyLoad)="getTableData($event)" [totalRecords]="totalRecords" [offset]="offset"
				  [rowsPerPage]="tableFilters?.rowsPerPage" [restoreOrderBy]="tableFilters?.orderBy"
				  [tableActions]="tableActions" [tableMessage]="tableMessage" [searchFields]="searchFields"
				  [loading]="loading" [canExportAll]="false"
				  (exportToCsvRequested)="exportToCSV(null, false)"
				  [headerTitle]="null"
				  [isTabbedTable]="isTabbedTable" [tabbedTitle]="tabbedTitle" [skeletonOverheadOptRight]="isTabbedTable"
				  [showNestedOverheadOpts]="isTabbedTable">
	<div *ngIf="isTabbedTable" class="he-specific-table-actions">
		<he-target-data-selector class="wallet-selector" [loading]="loading" [(selectedItem)]="selectedTargetData"
								 [options]="walletTypeLookup" skeletonWidth="120px"
								 (selectedItemChange)="onSelectedWalletTypeChange()"></he-target-data-selector>

		<button class="helio-orange-btn" pButton type="button" (click)="onShowAdjustWalletDialog()"
				[label]="btnLabel.ADJUST" [title]="btnLabel.ADJUST" icon="pi pi-sliders-h"
				[disabled]="(showAdjustWalletDialog === true) || !hasAdjustWalletBalPermission"></button>

		<div>
			<p-menu #menuPopup popup="popup" [model]="transferBtnMenuItems" appendTo="body"></p-menu>

			<button type="button" pButton class="helio-orange-btn" [icon]="'pi pi-arrow-right-arrow-left'"
					[label]="btnLabel.TRANSFERS" [title]="btnLabel.TRANSFERS"
					(click)="menuPopup.toggle($event);"></button>
		</div>

		<button class="helio-orange-btn" pButton type="button" [icon]="ADJUST_ICON_TAG"
				(click)="this.showAssignPspDialog = true"
				[label]="btnLabel.PSP" [title]="btnLabel.PSP" [disabled]="playerPspRoute === null"></button>
	</div>
</he-data-table-v3>

<!--Using if ensures the form is empty when init + it also ensures its destruction after use-->
<he-wallet-manual-adjust-dialog *ngIf="showAdjustWalletDialog" [balance]="walletBalance"
								[(visible)]="showAdjustWalletDialog" (submitEvent)="onAdjustWallet($event)"
								style="position: absolute" (closeEvent)="showAdjustWalletDialog = false">
</he-wallet-manual-adjust-dialog>

<!--Use of *ngIf here is also particularly important, since removal of the temp (class) flag used to
 elevate the cog opts relies on this -->
<he-bank-cards-dialog *ngIf="showCardsDialog" [(visible)]="showCardsDialog" style="position: absolute"
					  (closeEvent)="showCardsDialog = false"></he-bank-cards-dialog>

<he-bank-details-dialog *ngIf="showBankDetailsDialog" [(visible)]="showBankDetailsDialog"
						style="position: absolute" [playerPartialData]="this.playerPartialData"
						(closeEvent)="showBankDetailsDialog = false"></he-bank-details-dialog>

<he-exchange-house-details-dialog *ngIf="showExchangeHouseDialog" [(visible)]="showExchangeHouseDialog"
								  style="position: absolute" [playerPartialData]="playerPartialData"
								  (closeEvent)="showExchangeHouseDialog = false"></he-exchange-house-details-dialog>

<!--TODO: In future, when there are more options other than ZainCash and FastPay, the generation of e-wallets
     options and their can be made dynamic by filtering the res of /api/Payments/89/PaymentMethods for where
     paymentMethodTypeID == 3, i.e. e-wallet, associating its provider information,
     and pushing to transferBtnMenuItems-->
<he-e-wallet-details-dialog *ngIf="showEWalletDialog" [(visible)]="showEWalletDialog"
							[provider]="selectedEWalletProvider" [playerPartialData]="playerPartialData"
							(closeEvent)="showEWalletDialog = false; selectedEWalletProvider = null"
							style="position: absolute"></he-e-wallet-details-dialog>

<he-assign-psp-dialog *ngIf="showAssignPspDialog" [(visible)]="showAssignPspDialog" style="position: absolute"
					  [psp]="playerPspRoute" [playerPartialData]="this.playerPartialData"
					  (closeEvent)="showAssignPspDialog = false" (submitEvent)="onAssignPspRequest($event)">
</he-assign-psp-dialog>
