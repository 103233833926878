<!--*ngIf="gameFormat && (entryType === 'insertion') || (gameFormat && lotteryResults)" condition demands that
when in approval mode, both (gameFormat && lotteryResults) are required, to avoid error from undefined lottery-->

<he-base-dialog *ngIf="gameFormat && (entryType === 'insertion') || (gameFormat && lotteryResults)"
                [header]="entryType === 'insertion' ? 'Manually Insert Result' : 'Approve Result'"
                [visible]="visible && !staleValues" [isLoadingLookups]="staleValues" (visibleChange)="onCloseDialog($event)"
                [style]="{'min-width': '450px'}"
                [submitStyle]="{'min-width': '160px', 'text-align': 'left'}"
				[rejectLabel]="'Close'" [submitLabel]="submitLabel"
                (submitEvent)="onSubmit()" [disableSubmit]="!formGroup.valid || !canEnableSubmit">
    <p>{{entryType === 'insertion' ? INSERT_INFO : APPROVE_INFO}}</p>

    <form [formGroup]="formGroup" class="dialog-form-placement" autocomplete="off">
        <label>Draw Result</label>
        <ul class="selection" style="padding-left: 0; margin: 0;">
            <!--Board Generation-->
            <!--NB: ('boardNum' + i) is used as reference each boards formControls, for independent validation-->
            <ng-container *ngFor="let board of boards; index as i; first as isFirst">
                <!--The main board-->
                <ng-container *ngIf="isFirst; else secondaryBoards">
                    <li *ngFor="let item of board; index as indexInBoard; last as isLast" class="main-board"
                        [ngClass]="{isLast: isLast, isInsert: entryType === 'insertion'}">
                        <input pInputText pKeyFilter="int" autocomplete="off"
                               [formControl]="getFormArrayCtrAndInitVal(BOARD_IDENTIFIER_PREFIX + i, indexInBoard, board)"/>
                    </li>
                </ng-container>

                <!--All other secondary board(s)-->
                <ng-template #secondaryBoards>
                    <li class="spacer-li">
                        <span style="padding: 0 8px">+</span>
                    </li>

                    <li *ngFor="let item of board; index as indexInBoard; last as isLast" class="secondary-board"
                        [ngClass]="{isLast: isLast, isInsert: entryType === 'insertion'}">
                        <input pInputText pKeyFilter="int" autocomplete="off"
                               [formControl]="getFormArrayCtrAndInitVal(BOARD_IDENTIFIER_PREFIX + i, indexInBoard, board)"/>
                    </li>
                </ng-template>
            </ng-container>
        </ul>

        <ng-container *ngIf="entryType === 'approval'">
            <label for="actionRadioGroup">Action</label>
            <div id="actionRadioGroup" class="radio-btn-group">
                <p-radioButton id="accept" [value]="true"
                               formControlName="actionRadioGroup"></p-radioButton>
                <label for="accept">{{approvalOpts.ACCEPT}}</label>

                <p-radioButton id="reject" [value]="false"
                               formControlName="actionRadioGroup"></p-radioButton>
                <label for="reject">{{approvalOpts.REJECT}}</label>
            </div>
        </ng-container>

        <label for="reason">Reason</label>
        <textarea id="reason" formControlName="reason" pInputTextarea type="text" rows="5" cols="30"></textarea>
    </form>
</he-base-dialog>
