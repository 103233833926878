import {AfterViewInit, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {Router} from '@angular/router';
import {
	ApproveManualResultsData,
	BaseDraw,
	ChangeDrawDateData,
	DrawProcessingService,
	DrawType,
	DropDrawData,
	ManualInsertResultsData,
	noGameLotteryImageURL
} from '../shared';
import {AppConfigService} from '../../helio-core-services/services/app-config.service';
import {Draw} from '../../shared/models/draws/draw.model';
import {CommonDrawOperations} from '../shared/interface/common-draw-operations';
import {BoErrorHandlerService, ToastDisplayService} from '../../helio-core-services';
import {AppGuard} from '../../shared';
import {CountdownTimerService} from '../../shared/services/countdown-timer.service';
import {Subscription} from 'rxjs';

@Component({
	selector: 'he-draw-card',
	styleUrls: ['./draw-card.component.scss'],
	templateUrl: './draw-card.component.html'
})
export class DrawCardComponent extends CommonDrawOperations implements OnInit, AfterViewInit, OnDestroy {

	protected readonly noGameLotteryImageURL = noGameLotteryImageURL;

	@Input() gameDraw: Draw;
	@Input() includeViewMore = true;
	@Input() drawType: DrawType = DrawType.ACTIVE;
	@Input() @HostBinding('attr.compact') compactStyle = false;

	// Use to refresh the entire Draw catalogue (active or previous) rather than just the card after an update has taken place.
	@Output() refreshCatalogueEvent = new EventEmitter<void>();

	dayAndTimeOfNextDraw = '';
	menuItems: MenuItem[] = [];

	DrawTypeEnum = DrawType;

	countdownClock = CountdownTimerService.TIMER_PLACEHOLDER;
	saleEndsIn: string;

	startCountdownSub$: Subscription;

	constructor(
		private router: Router,
		protected appConfigService: AppConfigService,
		protected appGuard: AppGuard,
		private countdownTimerService: CountdownTimerService,
		protected drawService: DrawProcessingService,
		protected toastService: ToastDisplayService,
		protected boErrorHandlerService: BoErrorHandlerService) {
		super(appConfigService, appGuard, drawService, toastService, boErrorHandlerService);
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
		if (!this.gameDraw) {
			console.warn('DrawCard: gameDraw obj is undefined in card component.');
			return;
		}

		this.saleEndsIn = CountdownTimerService.getExactClockString(this.gameDraw.salesEndMinBeforeDraw, 'seconds');

		if (this.gameDraw?.daysPresent !== undefined && this.gameDraw?.daysPresent !== null) {
			this.dayAndTimeOfNextDraw = `every ${this.gameDraw.daysPresent} at ${this.gameDraw.timeOfDay}`;
		} else if (this.gameDraw?.timeOfDay) {
			this.dayAndTimeOfNextDraw = ` at ${this.gameDraw.timeOfDay}`;
		}

		if (this.compactStyle) {
			this.menuItems = this.getRowMenuItemTableActions(this.drawType) as MenuItem[];
		} else {
			this.menuItems = this.getRowMenuItemTableActions(
				this.drawType, false, {router: this.router, gameDraw: this.gameDraw}) as MenuItem[];
		}

		this.startCountdownSub$ = this.countdownTimerService.startCountdown(this.gameDraw.drawDate).subscribe({
			next: value => {
				this.countdownClock = value;
			}, error: () => {
				this.countdownClock = CountdownTimerService.TIMER_PLACEHOLDER;
			}
		});
	}

	ngOnDestroy() {
		this.onDestroy();
		this.releaseSubscriptions(this.startCountdownSub$);
	}

	changeDrawDate(data: ChangeDrawDateData): Promise<void> {
		// TODO - #refreshCatalogueEvent could be deprecated in favour of using the Promise callback?
		return new Promise<void>((resolve) => {
			super.changeDrawDate(data).then(() => {
				this.refreshCatalogueEvent.emit();
				resolve();
			}).catch((err) => {
				resolve();
				this.onDrawOpsImplErr('ChangeDrawDate', data, err);
			});
		});
	}

	async cancelDraw(data: DropDrawData) {
		super.cancelDraw(data).then(() => {
			this.refreshCatalogueEvent.emit();
		}).catch((err) => {
			this.onDrawOpsImplErr('DropDraw', data, err);
		});
	}

	async voidDraw(data: DropDrawData) {
		super.voidDraw(data).then(() => {
			this.refreshCatalogueEvent.emit();
		}).catch((err) => {
			this.onDrawOpsImplErr('VoidDraw', data, err);
		});
	}

	async insertManualResults(data: ManualInsertResultsData) {
		super.insertManualResults(data).then(() => {
			this.refreshCatalogueEvent.emit();
		}).catch((err) => {
			this.onDrawOpsImplErr('InsertManualResults', data, err);
		});
	}

	async approveManualResults(data: ApproveManualResultsData) {
		super.approveManualResults(data).then(() => {
			this.refreshCatalogueEvent.emit();
		}).catch((err) => {
			this.onDrawOpsImplErr('ApproveManualResults', data, err);
		});
	}

	private onDrawOpsImplErr(callName: string, draw: BaseDraw, err: Error) {
		this.handleDataRequestError(`${callName} ${draw?.drawID ?? ''}`, err);
	}

	handleDataRequestError(callName: string = '', error?: Error): void {
		super.handleDataRequestError();

		this.boErrorHandlerService.handleError(error, undefined, callName);
	}
}
