<he-base-dialog header="{{'Wallet Adjustment'}}" [(visible)]="visible"
				[isLoadingLookups]="isLoadingLookups || isLoadingOperator"
				[style]="{'max-width': '550px', width: '550px'}" (visibleChange)="onCloseDialog()"
				submitLabel="{{submitLabel}}" (submitEvent)="onSubmit()"
				[disableSubmit]="!form.valid || (calculatedAmountAfter < 0)">

	<form [formGroup]="form" class="dialog-form-placement" autocomplete="off">
		<label>Operator</label>
		<span class="he-disabled">{{operator?.tenantName}}</span>

		<label>{{ 'Wallet' }}</label>
		<div style="display: grid; row-gap: 4px;">
			<p-dropdown [options]="walletTypeOpt" formControlName="selectedWallet" placeholder="Please select a wallet"
						[disabled]="hasOneWallet" [attr.in-view-mode]="hasOneWallet">
			</p-dropdown>

			<span *ngIf="cannotManuallyAdjustAny" class="alert alert-danger">
				No option flagged as canManuallyAdjust.
			</span>
		</div>

		<label>Adjustment Type</label>
		<div class="radio-btn-group item-padding">
			<p-radioButton id="add" [value]="adjustmentOpts.ADD" formControlName="adjustmentType"></p-radioButton>
			<label for="add" class="radio-btn-label">{{ adjustmentOpts.ADD }}</label>

			<p-radioButton id="remove" [value]="adjustmentOpts.REMOVE" formControlName="adjustmentType">
			</p-radioButton>
			<label for="remove" class="radio-btn-label">{{ adjustmentOpts.REMOVE }}</label>
		</div>

		<!--Prefix used so as to avoid error when currencyCode is null-->
		<label for="amount">Amount</label>
		<p-inputNumber id="amount" formControlName="amount" allowEmpty="true" mode="decimal"
					   [prefix]="currencySymbol + ' '" [maxFractionDigits]="2"/>

		<label>Amount Before</label>
		<span class="non-editable info-input unselectable item-padding">
			<ng-container *ngIf="(amountBefore === undefined) else hasAmountBefore">{{ 'N/A' }}</ng-container>
			<ng-template #hasAmountBefore>
				{{ amountBefore | currency : balance?.currencyCode }}
			</ng-template>
		</span>

		<label id="amountAfterLabel">Amount After</label>
		<div style="display: grid; height: 43px">
			<span class="non-editable info-input item-padding" [ngClass]="{'p-error': (calculatedAmountAfter <= 0)}">
			{{ amountBefore === undefined ? 'N/A' : calculatedAmountAfter ? (calculatedAmountAfter | currency : balance.currencyCode) : 'N/A' }}
			</span>

			<small *ngIf="uiAmount && !selectedAdjustmentType" class="p-error" style="margin-top: 8px;">
				Please select an Adjustment Type.
			</small>
		</div>

		<label for="comment" style="padding-top: 8px">Comment</label>
		<textarea pInputTextarea id="comment" formControlName="comment" type="text" rows="5"></textarea>
	</form>
</he-base-dialog>
