import {Component, OnInit} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {FileDownloadUtility} from '../../shared/utilities/general-utilities/file-download.utility';
import {
	ApproveManualResultsData, BaseDraw, ChangeDrawDateData, DrawProcessingService, DrawType,
	DropDrawData, ManualInsertResultsData
} from '../shared';
import {
	ColumnType, DataTableAction, DataTableLazyLoadEvent, SearchField, SearchFieldType, TableDataResponse
} from '../../shared/components/data-table-v3';
import {
	AppConfigService,
	BoErrorHandlerService,
	BreadcrumbService,
	ConfirmDialogDisplayService,
	GameAdministrationService,
	TenantService,
	ToastDisplayService
} from '../../helio-core-services';
import {Draw, DrawSales, DrawWinStats} from '../../shared/models/draws/draw.model';
import {TenantDraw} from '../../shared/models/draws/tenant-draw.model';
import {ExportCSVUtility} from '../../shared/utilities/general-utilities/export-csv.utility';
import {CommonDrawOperations} from '../shared/interface/common-draw-operations';
import {CommonDrawOpsImpl} from '../shared/classes/common-draw-ops-impl';
import {ACTIVE_DRAW, PREVIOUS_DRAW} from '../../shared/constants/navigation-titles';
import * as ColumnNames from '../../shared/constants/ui-db-name-mappings';
import {ResponsiveContent} from '../../shared/interfaces/responsive-content';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {AppGuard} from '../../shared';
import {CommonNestedTableBase} from '../../shared/interfaces/common-table';
import {LookupService} from '../../shared/services/lookup.service';
import {isDefinedArray} from '../../shared/utilities/general-utilities/arrays.utils';
import {NestedTableClassScope} from '../../shared/interfaces/nested-table';
import {PublishDrawResultRequest} from '../../shared/models/games/publish-draw-results.model';

@Component({
	selector: 'he-draws-for-specific-game',
	styleUrls: ['./draws-for-specific-game.component.scss'],
	templateUrl: './draws-for-specific-game.component.html'
})
export class DrawsForSpecificGameComponent extends ResponsiveContent<Draw> implements OnInit,
	NestedTableClassScope<Draw, DrawSales | DrawWinStats> {

	allDrawsTableTitle: string;
	TITLE: string;

	gameGroupID = 0;
	gameNameTitle: string;
	gameTypeTitle = '';

	isTenant!: boolean;
	hasMoreThanOneTenant = true; // Needs to be set true so the necessary components are init, they will be removed if set false

	tabActiveIndex = 0;

	drawOfInterestID = 0;
	drawOfInterest: Draw = undefined; // This is the nextUpcomingDraw or firstPreviousDraw

	chosenDrawDate: Date = new Date();
	chosenGameGroupID = 0;

	tenantDraws: Draw[] | TenantDraw[] = [];
	// selectedTenantDraws: Draw[] | TenantDraw[] = [];

	tableActions: DataTableAction[] = [];

	activeTabIndex = 0;

	// Operators view - nested table
	nestedTables: Map<number, CommonNestedTableBase<DrawSales | DrawWinStats>> =
		new Map<number, CommonNestedTableBase<DrawSales | DrawWinStats>>();

	nestedUrlParams: HttpParams;
	nestedDataKey: string;
	nestedCols: any[];
	nestedSearchFields: SearchField[];

	drawType: DrawType;
	MyDrawType = DrawType;
	drawOpsImpl: CommonDrawOperations;
	insertResultDialogVis = false;

	private loggedOperatorIDs;
	private gotFirstDraw = false;

	private parentPageRouterLink: string;

	private getMainDataSub$: Subscription;
	private getCSVSub$: Subscription;
	private getNestedDataSub$: Subscription;
	private routeUrlSubs$: Subscription;

	constructor(
		protected appConfigService: AppConfigService,
		private route: ActivatedRoute,
		protected router: Router,
		protected appGuard: AppGuard,
		private drawProcService: DrawProcessingService,
		private tenantService: TenantService,
		private gameAdminService: GameAdministrationService,
		private lookupService: LookupService,
		private toastDisplayService: ToastDisplayService,
		private confirmationDisplayService: ConfirmDialogDisplayService,
		protected boErrorHandlerService: BoErrorHandlerService,
		private breadcrumbService: BreadcrumbService
	) {
		super(appConfigService, boErrorHandlerService);

		// this.nestedTable.tableMessage = this.appConfigService.tableEmptyMessage_loading;
		this.tableMessage = this.appConfigService.tableMissingDataMessage;

		this.isTenant = !this.appConfigService.isCentralServer;

		this.loggedOperatorIDs = AppGuard.getLoggedOperatorIDs();

		this.drawOpsImpl = new CommonDrawOpsImpl(appConfigService, appGuard, drawProcService, toastDisplayService, boErrorHandlerService);
	}

	ngOnInit() {
		this.routeUrlSubs$ = this.route.url.subscribe(urlArr => {
			const path = urlArr[0].path;

			if (path === 'previous-draws-for-game') {
				this.drawType = DrawType.PREVIOUS;
				this.TITLE = PREVIOUS_DRAW;
				this.parentPageRouterLink = '/draw-processing/previous-draws';
			} else if (path === 'upcoming-draws-for-game') {
				this.drawType = DrawType.ACTIVE
				this.TITLE = ACTIVE_DRAW;
				this.parentPageRouterLink = '/draw-processing/upcoming-draws';
			} else {
				throw new Error(`Dynamic adaptation of ${DrawsForSpecificGameComponent} has not been handled.`)
			}

			this.allDrawsTableTitle = `All ${this.TITLE}`

			this.gameGroupID = +urlArr[1].path;

			this.initDataTable();

			this.getTableData(undefined, this.params);

			this.fetchLookups();
		});
	}

	ngAfterViewInit() {
	}

	ngOnDestroy() {
		this.releaseSubscriptions(
			this.routeUrlSubs$, this.getMainDataSub$, this.getCSVSub$, this.getNestedDataSub$
		)
	}

	initDataTable() {
		this.dataKey = ColumnNames.DRAW_ID.DB;
		this.nestedDataKey = ColumnNames.TENANT_NAME.DB;

		const linesColumns = [
			{
				field: ColumnNames.DRAW_LINES.DB,
				header: ColumnNames.DRAW_LINES.UI,
				columnType: ColumnType.Number
			},
			{
				field: ColumnNames.PAID_LINES.DB,
				header: ColumnNames.PAID_LINES.UI,
				columnType: ColumnType.Number
			},
			{
				field: ColumnNames.FREE_LINES.DB,
				header: ColumnNames.FREE_LINES.UI,
				columnType: ColumnType.Number
			}
		];

		this.cols = [
			{
				field: ColumnNames.DRAW_ID.DB,
				header: ColumnNames.DRAW_ID.UI
			},
			...linesColumns,
			{
				field: ColumnNames.DRAW_DATE.DB,
				header: ColumnNames.DRAW_DATE.UI,
				columnType: ColumnType.Date
			}
		];

		if (this.drawType === DrawType.PREVIOUS) {
			const startIndex = this.cols.findIndex((entry => entry.field === ColumnNames.DRAW_DATE.DB));

			this.cols.splice(startIndex, 0, {
					field: ColumnNames.WINNING_LINES.DB,
					header: ColumnNames.WINNING_LINES.UI,
					columnType: ColumnType.Number
				},
				{
					field: ColumnNames.PRIZE_MONEY.DB,
					header: ColumnNames.PRIZE_MONEY.UI,
					columnType: ColumnType.Money
				},
				{
					field: ColumnNames.DRAW_RESULT.DB,
					header: ColumnNames.DRAW_RESULT.UI,
					sortable: false
				});
		}

		// To cater for the fact that the API is currently returning a different field for this!
		if (this.drawType === DrawType.ACTIVE) {
			this.cols.push({
				field: ColumnNames.DRAW_EXPANDED_STATUS.DB,
				header: ColumnNames.DRAW_EXPANDED_STATUS.UI
			});
		} else {
			this.cols.push({
				field: ColumnNames.DRAW_STATUS.DB,
				header: ColumnNames.DRAW_STATUS.UI
			});
		}

		// NB: Currently out of scope for current spec
		/*if (this.isTenant && !this.hasMoreThanOneTenant) {
			this.cols.splice(1, 0,
				{field: ColumnNames.TENANT_NAME.DB, header: ColumnNames.TENANT_NAME.UI, sortable: !this.isTenant});
		}*/

		this.nestedCols = [
			{
				field: ColumnNames.TENANT_NAME.DB,
				header: ColumnNames.TENANT_NAME.UI,
				sortable: !this.isTenant
			},
			...linesColumns
		];

		const linesSearchFields = [
			{property: ColumnNames.DRAW_LINES.DB, label: ColumnNames.DRAW_LINES.UI, type: SearchFieldType.Number},
			{property: ColumnNames.PAID_LINES.DB, label: ColumnNames.PAID_LINES.UI, type: SearchFieldType.Number},
			{property: ColumnNames.FREE_LINES.DB, label: ColumnNames.FREE_LINES.UI, type: SearchFieldType.Number}
		]

		if (this.drawType === DrawType.PREVIOUS) {
			linesSearchFields.push(
				{property: ColumnNames.WINNING_LINES.DB, label: ColumnNames.WINNING_LINES.UI, type: SearchFieldType.Number},
				{property: ColumnNames.PRIZE_MONEY.DB, label: ColumnNames.PRIZE_MONEY.UI, type: SearchFieldType.Number},
				{property: ColumnNames.DRAW_RESULT.DB, label: ColumnNames.DRAW_RESULT.UI, type: SearchFieldType.StringDefault}
			);
		}

		this.searchFields = [
			{
				property: ColumnNames.DRAW_ID.DB,
				label: ColumnNames.DRAW_ID.UI,
				type: SearchFieldType.Number
			},
			...linesSearchFields,
			{
				property: ColumnNames.DRAW_DATE.DB,
				label: ColumnNames.DRAW_DATE.UI,
				type: SearchFieldType.Date,
				isRange: true
			}
		];

		if (this.drawType === DrawType.ACTIVE) {
			this.searchFields.push({
				property: ColumnNames.DRAW_EXPANDED_STATUS_ID.DB,
				label: ColumnNames.DRAW_EXPANDED_STATUS_ID.UI,
				type: SearchFieldType.NumberList,
				listOptions: [], // this.drawStatusLookups
				isArraySearch: false
			});
		} else {
			this.searchFields.push({
				property: ColumnNames.DRAW_STATUS_ID.DB,
				label: ColumnNames.DRAW_STATUS_ID.UI,
				type: SearchFieldType.NumberList,
				listOptions: [], // this.drawStatusLookups
				isArraySearch: false
			});
		}

		this.nestedSearchFields = []; // Not currently supported

		// Table and Row actions
		this.tableActions = this.getDefaultTableActions();

		const data: DataTableAction[] = this.drawOpsImpl.getRowMenuItemTableActions(this.drawType, true) as DataTableAction[];

		this.tableActions.push(...data);
	}

	fetchLookups(): void {
		/*
		// This is redundant for now because:
		// 1. "Operators" is not currently a column on table
		// 2. AdvancedSearch is not currently supported on nestedTable

		this.tenantService.getTenantsLookup().subscribe(res => {
			res.forEach((item) => {
				this.tenantLookups.push({label: item.tenantName, value: item.tenantID});
			});

			if (this.isTenant) {
				if (!this.hasMoreThanOneTenant) {
					this.searchFields.push({
						property: 'tenantID', label: 'Operator',
						type: SearchFieldType.NumberList,
						listOptions: this.tenantLookups,
						isArraySearch: false
					});
				}
			} else {
				this.nestedTable.searchFields.push({
					property: 'tenantID', label: 'Operator',
					type: SearchFieldType.NumberList,
					listOptions: this.tenantLookups,
					isArraySearch: false
				});
			}
		}, error => {
			this.boErrorHandlerService.handleError(error);
		});*/

		// get transaction types
		const drawStatusObservable = (this.drawType === DrawType.ACTIVE) ?
			this.lookupService.getActiveDrawStatuses() :
			this.lookupService.getPreviousDrawStatuses();

		drawStatusObservable.subscribe({
			next: (res) => {
				if (!isDefinedArray(res)) {
					return;
				}

				// this.drawStatusLookups = res;

				// Update the previously added transType object by reference
				const drawStatusObj = this.searchFields.find(value => {
					return value.property ===
						((this.drawType === DrawType.ACTIVE) ? ColumnNames.DRAW_EXPANDED_STATUS_ID.DB : ColumnNames.DRAW_STATUS_ID.DB);
				});
				drawStatusObj.listOptions = res; // this.drawStatusLookups;
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	setTitleAndBreadCrumb(draw: Draw) {
		if (draw.gameGroupName) {
			this.gameNameTitle = draw.gameGroupName;

			this.allDrawsTableTitle = `All ${this.TITLE} for ${this.gameNameTitle}`

			this.breadcrumbService.setItems([
				{label: 'Draws'},
				{label: this.TITLE, routerLink: this.parentPageRouterLink},
				{label: `${this.gameNameTitle}`}
			]);
		}
	}

	getTableData(event?: DataTableLazyLoadEvent, forceSearchParam?: HttpParams) {
		super.getTableData(event, forceSearchParam);

		// Must be called first here since observables - below - depend on it for their init
		this.params = this.computeTableDataHttpParams(event);

		const observables = {
			getDrawForGame: (this.drawType === DrawType.ACTIVE) ?
				this.drawProcService.getUpcomingDrawForGame(this.gameGroupID) :
				this.drawProcService.getLatestPreviousDrawForGame(this.gameGroupID),
			getDraws: (this.drawType === DrawType.ACTIVE) ?
				this.drawProcService.getActiveDrawsForGame(this.gameGroupID, this.params) :
				this.drawProcService.getAllPreviousDrawsForGame(this.gameGroupID, this.params),
		};

		this.beforeTableDataServiceCall(this.getMainDataSub$);

		this.getMainDataSub$ = forkJoin(observables).subscribe({
			next: (res: any) => {
				// getActiveDrawForGame
				this.drawOfInterest = res.getDrawForGame as Draw;

				if (!this.drawOfInterest) {
					this.tableMessage = this.appConfigService.tableMissingDataMessage;
					this.loading = false;
				} else {
					// first time only
					this.gotFirstDraw = true;
				}

				this.hasMoreThanOneTenant = this.drawOfInterest['enabledForTenantIds'];

				// getActiveDraws
				this.tenantDraws = res.getDraws.resultSet;
				this.totalRecords = res.getDraws.totalRowCount;

				if (this.tenantDraws && (this.totalRecords > 0)) {
					this.gameTypeTitle = `${this.drawOfInterest.gameGroupName} | ${this.drawOfInterest.gameCurrencyCode}`;
				}

				this.setTitleAndBreadCrumb(this.drawOfInterest);

				this.loading = false;
			},
			error: error => {
				this.hasMoreThanOneTenant = false;
				this.tableMessage = `${this.appConfigService.genericErrorMessage}. Failed to GetActiveDrawsForGame and/or getActiveDraws.`;
				this.loading = false;
				this.boErrorHandlerService.handleError(error);
			}
		});

	}

	getNestedTableData(event: DataTableLazyLoadEvent, expandedDraw: Draw, index: number): void {
		if (!this.nestedTables.has(index)) {
			this.nestedTables.set(index, new CommonNestedTableBase<TenantDraw>());
			// this.nestedTables.get(index).parentData = expandedDTO;
		}

		this.nestedTables.get(index).loading = true;

		this.nestedUrlParams = this.computeTableDataHttpParams(event);

		const observable: Observable<TableDataResponse<DrawSales | DrawWinStats>> = (this.drawType === DrawType.ACTIVE) ?
			this.drawProcService.getActiveDrawsByTenant(expandedDraw.drawID, this.nestedUrlParams) :
			this.drawProcService.getPastDrawsByTenant(expandedDraw.drawID, this.nestedUrlParams);

		this.getNestedDataSub$ = observable.subscribe({
			next: res => {
				if (res.resultSet) {
					expandedDraw['breakdown'] = res.resultSet as DrawSales | DrawWinStats[]; // this.nestedTable.data DrawSales | DrawWinStats
					this.nestedTables.get(index).totalRecords = res.totalRowCount;
					this.nestedTables.get(index).loading = false;

					if (res.resultSet.length === 0) {
						this.nestedTables.get(index).tableMessage = this.appConfigService.tableMissingDataMessage;
					}
				}
			},
			error: error => {
				this.nestedTables.get(index).tableMessage = this.appConfigService.genericErrorMessage;
				this.nestedTables.get(index).loading = false;
				this.boErrorHandlerService.handleError(error, undefined, 'Draw for Tenants');
			}
		});
	}

	handleTabChange(e) {
		// Temp solution to handle eager init of <draw-sales> and <winners> as part of <p-tabView> which results in undefined
		// values for this.gameDraw?.drawID
		// TODO: Issue will be permanently fixed when PrimeNG is upgraded to >16.20 where [selectOnFocus]="true" can be utilised.
		this.activeTabIndex = e.index;
	}

	exportToCSV(selectedDraws: Draw[], isAllDataSelected: boolean): void {
		this.loading = true;

		const exportHttpParams = ExportCSVUtility.getHttpParams(
			this.params, selectedDraws, isAllDataSelected, ColumnNames.DRAW_ID.DB
		);

		const observable = (this.drawType === DrawType.ACTIVE) ?
			this.drawProcService.getActiveDrawsForGameCsv(this.gameGroupID, exportHttpParams, this.cols) :
			this.drawProcService.getAllPreviousDrawsForGameCsv(this.gameGroupID, exportHttpParams, this.cols);

		this.releaseSubscriptions(this.getCSVSub$);

		this.getCSVSub$ = observable.subscribe({
			next: res => {
				if (res !== undefined) {
					// download file
					const drawTypeStr = this.TITLE.replace(/\s/g, '');
					FileDownloadUtility.downloadCsv(
						res, `${drawTypeStr}ForGameID_${this.gameGroupID}`);
				}
				this.loading = false;
			},
			error: error => {
				this.loading = false;
				this.boErrorHandlerService.handleError(error, undefined, 'Exporting to CSV');
			}
		});
	}

	private onDrawOpsImplErr(callName: string, draw: BaseDraw, err: Error) {
		// this.drawOpsImpl.loading is used independent of loading, which is for table data loading,
		// so that these do not conflict with each other. I.e. after a draw op is successful, the new data
		// for table will not necessarily be available at the same time; worse it might return error
		this.drawOpsImpl.loading = false;
		this.handleDataRequestError(`${callName} ${draw.drawID}`, err);
	}

	cancelDraw($event: DropDrawData) {
		this.drawOpsImpl.loading = true;

		this.drawOpsImpl.cancelDraw($event).then(() => {
			this.getTableData(undefined, this.params);
		}).catch(err => {
			this.onDrawOpsImplErr('Cancelling Draw', $event, err);
		});
	}

	voidDraw($event: DropDrawData) {
		this.drawOpsImpl.loading = true;

		this.drawOpsImpl.voidDraw($event).then(() => {
			this.getTableData(undefined, this.params);
		}).catch(err => {
			this.onDrawOpsImplErr('Voiding Draw', $event, err);
		});
	}

	changeDrawDate($event: ChangeDrawDateData) {
		this.drawOpsImpl.loading = true;

		this.drawOpsImpl.changeDrawDate($event).then(() => {
			this.getTableData(undefined, this.params);
		}).catch(err => {
			this.onDrawOpsImplErr('Changing Draw', $event, err);
		});
	}

	insertManualResults($event: ManualInsertResultsData) {
		this.drawOpsImpl.loading = true;

		this.drawOpsImpl.insertManualResults($event).then(() => {
			this.getTableData(undefined, this.params);
		}).catch(err => {
			this.onDrawOpsImplErr('Inserting Manual Res', $event, err);
		});
	}

	approveManualResults($event: ApproveManualResultsData) {
		this.drawOpsImpl.loading = true;

		this.drawOpsImpl.approveManualResults($event).then(() => {
			this.getTableData(undefined, this.params);
		}).catch(err => {
			this.onDrawOpsImplErr('Approving Manual Res', $event, err);
		});
	}

	onInsertResultDialogVisChange($event: boolean) {
		this.drawOpsImpl.displayInsertResultDialog = $event;
	}

	onApproveResultDialogVisChange($event: boolean) {
		this.drawOpsImpl.displayApproveResultDialog = $event;
	}

	onPublishDrawResultDialogVisChange(flag: boolean) {
		this.drawOpsImpl.displayPublishDrawResultDialog = flag;
	}

	onPublishResultSubmit(success: boolean) {
		if (success) {
			this.getTableData()
		}
	}
}
