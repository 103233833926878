import {
	AfterViewInit,
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges, OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef, ViewChild
} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {SelectItem} from 'primeng/api';
import {SearchField} from './advanced-search/search-field.model';
import {CheckboxColumnPos} from './shared/checkbox-column-pos.enum';
import {SearchEventType, SubmitSearchEvent} from './advanced-search/submit-search-event.model';
import {ObjPropsStringDotNotationUtility} from '../../utilities';
import {AppConfigService} from '../../../helio-core-services/services/app-config.service';
import {PrimeNGColumn} from './shared/primeng-col-extend.model';
import {DataTableAction} from './shared/data-table-action.model';
import {DataTableLazyLoadEvent} from './shared/data-table-lazy-load-event.model';
import {RowSelectChangeEvent} from './shared/row-select-change-event.model';
import {ColumnType} from './shared/column-type.enum';
import {AdvancedSearchService} from 'src/app/helio-core-services';
import {ColumnDataComponent, ColumnDataPipe} from './column-data';
import {HeMenuItem} from '../../models/primeng-overrides/he-menu-item.interface';
import {numToPxStr} from '../../utilities/general-utilities/string.utility';
import {HeLazyLoadEvent} from '../../models/primeng-overrides/he-lazy-load-event';
import {_lodash} from '../../global-libraries';
import {BehaviorSubject} from 'rxjs';
import {SkeletonOverheadItemCount} from '../../../skeletons/table/skeleton-table.component';
import {TableLazyLoadEvent, TableRowReorderEvent} from 'primeng/table';
import {AdvancedSearchComponent} from './advanced-search';

@Component({
	selector: 'he-data-table-v3',
	styleUrls: [
		'./data-table-v3.component.scss'
	],
	templateUrl: './data-table-v3.component.html'
})
export class DataTableV3Component implements OnInit, OnChanges, AfterViewInit, OnDestroy {

	private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _offset$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	TAG = DataTableV3Component.name;
	@Input() columns: PrimeNGColumn[] = [];
	@Input() dataKey: string;

	@Input() paginator = true;
	@Input() deliverInitialLazyCall = false; // To allow nested tables to automatically load since they cannot be easily lazily invoked
	@Input() lazy = true; // i.e. lazy pagination
	@Input() totalRecords: number;

	@Input() selectionCheckbox: CheckboxColumnPos;
	@Input() tableActions: DataTableAction[];
	@Input() showHeader = true; // This is necessary because some usages do not require any header - standalone or tabbed
	@Input() headerTitle: string | null | undefined = null; // For standalone tables
	@Input() tabbedTitle = ''; // For use when table is used within tabs
	@Input() isTabbedTable = false;
	@Input() showNestedOverheadOpts = true;
	@Input() expandableRows = false;
	@Input() selectedRows: any[] = [];
	@Input() resizableColumns = true;
	// @Input() scrollable = false;
	@Input() tableMessage!: string;
	@Input() searchFields: SearchField[];
	@Input() initialFilter: SubmitSearchEvent;
	@Input() parentData: any = undefined;
	@Input() editable = false;
	@Input() columnResizeMode: 'fit' | 'expand' = 'expand';
	@Input() canExportAll = false; // False by default since the current project no longer requires it for all tables
	@Input() showExportAll = false;

	@Input() skeletonOverheadOptRight = false;
	@Input() skeletonOverheadOptLeft: boolean;
	@Input() skeletonOverheadItemCount: SkeletonOverheadItemCount = 3;
	@Input() skeletonHasHeader = true;
	@Input() skeletonHasFooter = true;

	/**
	 * @deprecated TODO: Implement as now been used in many places
	 */
	@Input() hasHeader = true;

	/**
	 * @deprecated TODO: Implement as now been used in many places
	 */
	@Input() hasFooter = true;

	// private _setRwdHeaders: boolean;
	@Output() lazyLoad: EventEmitter<DataTableLazyLoadEvent> = new EventEmitter<DataTableLazyLoadEvent>();
	@Output() rowSelectChange: EventEmitter<RowSelectChangeEvent> = new EventEmitter<RowSelectChangeEvent>();
	@Output() cellEditComplete: EventEmitter<any> = new EventEmitter<any>();
	@Output() selectedRowsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
	@Output() exportToCsvRequested: EventEmitter<any> = new EventEmitter<any>();

	@ContentChild(TemplateRef, {static: false}) rowTemplate: TemplateRef<ElementRef>;
	@ViewChild('advancedSearchComponent') advancedSearchComponent: AdvancedSearchComponent;
	@ViewChild('myTableV3', {static: false}) myTableV3: any;

	columnType: typeof ColumnType = ColumnType;
	checkboxColumnPos: typeof CheckboxColumnPos = CheckboxColumnPos;
	tableActionsMenu: HeMenuItem[];
	bulkActionsMenu: HeMenuItem[];
	rowActionsMenu: HeMenuItem[];
	rowActionData: any;
	isBulkMode = false;
	hasBulkActions = false;
	hasTableActions = false;
	showAdvancedSearch = false;

	@Input() rowsPerPage: ValidRows = 10;
	@Output() rowsPerPageChange = new EventEmitter<ValidRows>();
	rowsPerPageOpts: SelectItem[] = [
		{label: '10', value: 10},
		{label: '25', value: 25},
		{label: '50', value: 50},
		{label: '100', value: 100},
		{label: '250', value: 250}
	];

	firsPage = 0;
	rowColspan = 0;

	@Input() reorderableRows = false;

	@Output() rowReorderEvent = new EventEmitter<TableRowReorderEvent>();

	@HostBinding('attr.virtual-scroll-item-size') virtualScrollItemSizePX = 48;
	@HostBinding('attr.scroll-container-height') scrollContainerHeightPX = '0px';

	MyColumnType = ColumnType;
	listForInlineEditColumnType: ColumnType = ColumnType.ListForInlineEdit;
	dateColumnType: ColumnType = ColumnType.Date;

	setRwdHeaders = false;
	// moneyFormat: string;
	private dataPipe: ColumnDataPipe;

	// timezoneOffset: string;
	private lastPrimeNGTableEvent: TableLazyLoadEvent;
	// numberFormat: string;
	private urlPath = window.location.hash;

	private calRowInterval: number;

	// Used with the template instead of using loading directly, to allow wait of 200ms delay before application.
	// Set by default to true so that on the initial display of the table, tableEmptyMessage is not first shown
	isLoading = true;

	private rowHeightInit = false;

	private _data$ = new BehaviorSubject<any[]>(null);

	// For programmatically highlighting a column to reflect the orderBy state which are being restored
	private _restoreOrderBy$ = new BehaviorSubject<string>(undefined);
	orderByRestored?: string;
	orderDirectionRestored?: 1 | -1; // 1 for asc, -1 for desc

	constructor(
		private appConfigService: AppConfigService,
		private advancedSearchService: AdvancedSearchService) {
		this.tableMessage = this.tableMessage ?? this.appConfigService.tableEmptyMessage_loading;
	}

	get data() {
		return this._data$.getValue();
	}

	@Input() set data(value: any[]) {
		this._data$.next(value);
	}

	@Input() set loading(value: boolean) {
		this._loading$.next(value);
	}

	get loading() {
		return this._loading$.getValue();
	}

	@Input() set offset(value: number) {
		this._offset$.next(value);
	}

	get offset() {
		return this._offset$.getValue();
	}

	get restoreOrderBy() {
		return this._restoreOrderBy$.getValue();
	}

	@Input() set restoreOrderBy(value: string) {
		this._restoreOrderBy$.next(value);
	}

	// TODO: Replace this pattern with tableAction from ngOnChanges
	/*private _testTableAction$ = new BehaviorSubject<DataTableAction[]>(null);
	@Input() set testTableAction(value: DataTableAction[]) {
		this._testTableAction$.next(value);
	}

	get testTableAction() {
		return this._testTableAction$.getValue();
	}*/

	get isAllDataSelected() {
		return (this.selectedRows.length === this.totalRecords);
	}

	ngOnInit() {
		// this.dateFormat = this.appConfigService.defaultDateFormat;
		// this.timezoneOffset = this.appConfigService.defaultTimezoneOffset;
		// this.numberFormat = this.appConfigService.defaultNumberFormat;
		// this.moneyFormat = this.appConfigService.defaultMoneyFormat;

		// TODO - This was commented out as it caused nested tables to duplicate their parent AdvancedSearch UI
		//  on activation when immediately followed by Search
		// this.showAdvancedSearch = this.advancedSearchService.shouldShowAdvancedSearch(this.urlPath);

		this.generateTableActions();
		this.setExpandedRowColspan();

		if ((this.hasBulkActions || this.expandableRows || this.selectionCheckbox !== undefined) && this.dataKey === undefined) {
			throw new Error('Data Table has Row Selection or Row Expand enabled. Please set a dataKey to uniquely identify each row.');
		}

		/*if (!this.lazy) {
			this.totalRecords = this.data.length;
			if (this.totalRecords === 0) {
				this.tableMessage = this.appConfigService.tableMissingDataMessage;
			}
		}*/

		this.dataPipe = new ColumnDataPipe();

		// TODO: Temp fix for determining table row height (this is necessary because the size of the row can vary,
		//  and if not exact, causes virtual scroll to flicker)
		this._data$.subscribe((value) => {
			if (this.rowHeightInit || !value || value.length < 1) {
				return;
			}

			let count = 0;

			this.calRowInterval = window.setInterval(() => {
				count++;

				if (this.calculateRowHeight() || count >= 10) {
					window.clearInterval(this.calRowInterval);
					this.rowHeightInit = true;
				}

			}, 400);
		});

		this._restoreOrderBy$.subscribe((value: string) => {
			const parts = value ? value.trim().split(/\s+/) : null;

			if (!parts || parts.length !== 2) {
				this.orderByRestored = null;
				this.orderDirectionRestored = null;
			} else {
				// 1 for asc, -1 for desc
				this.orderByRestored = parts[0];
				this.orderDirectionRestored = (parts[1] === 'asc' ? 1 : -1);
			}

			// console.log('DataTable: restoreOrderBy =', this.restoreOrderBy)
		});

		/*this._loading$.subscribe((value: boolean) => {
			if (value) {
				if (this.delayLoadingTimer) {
					window.clearTimeout(this.delayLoadingTimer);
				}

				// On first getting loading true, set a delay before this is assigned to the field used
				// in template, isLoading
				this.delayLoadingTimer = window.setTimeout(() => {
					this.isLoading = true;
				}, 500);
			} else {
				// If loading is later set to false - indicating that the data has arrived before the timer has lapsed -
				// clear the timer and enforce that false is assigned to isLoading
				window.clearTimeout(this.delayLoadingTimer);
				this.isLoading = false;
			}
		});*/

		this._offset$.subscribe(value => {
			if (value === 0) {
				this.firsPage = 0; // Reset pagination position to 0 when the offset is set to 0
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		//region Dynamically set virtualScrollItemSize (after a loading cycle has completed), which lends itself to RWD views

		const loadingChange = changes['loading'];
		const completedLoadCycle = loadingChange && (loadingChange.currentValue !== loadingChange.previousValue);
		if (completedLoadCycle && (loadingChange.currentValue === false)) {
			// this.calculateTableDimension();
		}

		//endregion

		// region Update tableActions dynamically when its Input object changes

		// NB: This relies on reassigning empties, i.e. [], to this.tableActionsMenu and other arrays of interest
		// so that duplicates are not created.
		const tableActionsChg = changes['tableActions'];
		if (tableActionsChg && !tableActionsChg.firstChange &&
			!_lodash.isEqual(tableActionsChg.currentValue, tableActionsChg.previousValue)) {
			this.generateTableActions();
			this.setExpandedRowColspan();
		}

		// endregion
	}

	ngAfterViewInit() {
		this.initialLoad();
		this.calculateTableDimension();

		window.addEventListener('resize', this.calculateTableDimension);
	}

	ngOnDestroy() {
		window.removeEventListener('resize', this.calculateTableDimension);
	}

	calculateTableDimension = () => {
		// Compute scroll container height
		// p-table .p-datatable-wrapper
		const scrollContainer = document.querySelector(`p-table`) as HTMLElement;
		if (scrollContainer) {
			// const value = scrollContainer.offsetHeight - 4;
			// 49px represents the height of the table caption (where Bulk and AdvancedSearch buttons live)
			// 46px represents the height of the table footer
			// The
			this.scrollContainerHeightPX = numToPxStr(scrollContainer.offsetHeight - 49 - 46);

			// needed by .p-scroller in CSS (selector must be part, or a parent, of this host)
			// scrollContainer.style.setProperty('--scrollContainerHeightPX', this.scrollContainerHeightPX);
			scrollContainer.style.setProperty('--scrollContainerHeightPX', '100%');
		}

		/*const oldState = this.setRwdHeaders;
		this.setRwdHeaders = window.innerWidth < MIN_DESKTOP_CONTENT_WIDTH;

		if (oldState !== this.setRwdHeaders) {
			this.calculateRowHeight();

			// TODO: temp solution for forcing redraw for the table rows after rwd is flagged or removed
			this.rowsToDisplayChange(this.rowsPerPage);
		}*/
	}

	calculateRowHeight() {
		// Solution assume that the height of the first row is the same as other rows
		const firstRow = document.querySelector(`tbody tr`) as HTMLElement;

		if (firstRow) {
			this.virtualScrollItemSizePX = firstRow.offsetHeight;
			return true;
		}
		return false;
	}

	initialLoad() {
		const initialEvent = this.initialFilter ?? ({
			initialLoad: true
		} as HeLazyLoadEvent);

		this.loadLazyDataTable(initialEvent); // Invoke the initial load after view has loaded
	}

	/**
	 * Callback initiated for AdvancedSearch, onNumberOfRows change, etc., but the page is initialised in {@link ngAfterViewInit}.
	 */
	loadLazyDataTable(event: HeLazyLoadEvent): void {
		if (this.loading ||
			(event.initialLoad && this.deliverInitialLazyCall === false) ||
			(!event.initialLoad && !event.rowChange && !event.searchType && !this.validPrimeNgEvent(event))) {
			return;
		}

		const emitData: DataTableLazyLoadEvent = {
			primeNGEvent: undefined,
			take: (event?.rows && event.rows > 0) ? event.rows : this.rowsPerPage, // take 0 is not accepted, but offset 0 is.
			offset: event?.first ?? 0
		}

		if (event.searchType === SearchEventType.CLOSE || event.searchType === SearchEventType.CLEAR) {
			// emitData.offset = 0;
			this.firsPage = 0;
			// this.myTableV3._first = 0;
		}

		if (!event.initialLoad) {
			let advancedSearchData: SubmitSearchEvent;

			if (this.advancedSearchComponent &&
				event.searchType !== SearchEventType.CLOSE && event.searchType !== SearchEventType.CLEAR) {
				advancedSearchData = this.advancedSearchComponent.getFilterStringForSubmit(); // denotes that Advanced search UI is active
			}

			if (event.searchType || advancedSearchData) {
				if (event.sortField !== undefined) {
					const sortOrder = (event.sortOrder === 1) ? 'asc' : 'desc';
					emitData.orderBy = `${event.sortField} ${sortOrder}`;
				}

				emitData.advancedSearchData = advancedSearchData ?? event;
			} else {
				// If NOT Initial load and AdvancedSearch, then this must be a primeNG event
				emitData.primeNGEvent = event;
				this.lastPrimeNGTableEvent = event;
			}
		}

		emitData.urlParams = this.getURLSearchParams(emitData);

		// Clear current (old) data as implementing Component is expected to fetch new data.
		// This is necessary so if an unhandled error occurs, say, in AdvancedSearch query,
		// stale data is not mistaken for expected data. However, it requires that tables
		// handle re-fetch requests without exception.
		// NB: Naturally, this pattern will not work with uses of FIND UI, if this method is not invoked,
		// in such cases, it should be enforced directly in the relevant call to get data. (this structure means
		// that a specific implementation is not relied on in implementing classes)
		// this.data = [];

		this.lazyLoad.emit(emitData);
	}

	/**
	 * @summary This predicate is necessary because PrimeNG initiates multiple callbacks (3 for launch and
	 * 2 for filter, sorting and paging), which causes the same data to be re-fetched, thereby placing UI
	 * in a prolonged loading state even though data is ready. For this reason the initial load is handled manually and
	 * this predicate is used to ignore duplicate requests.
	 */
	validPrimeNgEvent(event: HeLazyLoadEvent) {
		const isSortingEvent = event.sortField !== undefined;
		// this.firstRow is bound to the paginator, when this is not zero: a valid paging request exists back to 0
		//  event.first denotes a valid request - contained in the event - to page forward
		const isPagingEvent = (event.first > 0) || (this.firsPage > 0);
		const flag = (event.rows > 0) && (isSortingEvent || isPagingEvent);
		return flag && (event.rows !== event.last); // Represent an original primeEvent and not a duplicate callback
	}

	handleAdvancedSearch(event: SubmitSearchEvent): void {
		if (this.loading) {
			console.warn(this.TAG, 'handleAdvancedSearch: TABLE IS CURRENTLY IN LOAD STATE - ABORTING', event);
		} else if (event.searchType === SearchEventType.CLOSE) {
			this.showAdvancedSearch = false;
			this.advancedSearchService.fields.delete(this.urlPath);
			this.advancedSearchService.setShowAdvancedSearch(this.urlPath, false);
		} else if (event.searchType === SearchEventType.SUBMIT) {
			// Do nothing
			this.firsPage = 0;
		}

		this.loadLazyDataTable(event);
	}

	showAdvancedSearchClick(): void {
		this.showAdvancedSearch = !this.showAdvancedSearch;
	}

	rowActionsClick(data: any): void {
		this.rowActionData = data;

		// When the cog is clicked for each row, the presence of visibilityPredicate indicates that the menuItem is
		//  predicated on a particular condition, use the defined function to test whether the item is hidden or shown.
		this.rowActionsMenu.forEach(item => {
			if (item.visibilityPredicate) {
				item.visible = item.visibilityPredicate(this.rowActionData)
			}

			if (item.enablePredicate) {
				item.disabled = !item.enablePredicate(this.rowActionData);
			}
		})
	}

	// TODO: Review once PrimeNG Turbo Table is implemented. (https://www.primefaces.org/primeng/#/table)
	// Current implementation is buggy.
	// Function resets data table to first page.

	bulkActionsClick(): void {
		this.isBulkMode = !this.isBulkMode;
		this.showExportAll = !this.showExportAll;

		if (!this.isBulkMode) {
			this.rowColspan--;

			// empty selected rows array
			this.selectedRows.splice(0);

			// slice array to trigger change in array
			this.selectedRows = this.selectedRows.slice();
		} else {
			this.rowColspan++;
		}
	}

	// getTooltipString(data: any[]): string {
	// 	return data.join(', ');
	// }

	// When table is set to lazy, call to server is not triggered when viewing first page and selecting a larger page size.
	rowsToDisplayChange = (value: ValidRows): void => {
		if (this.rowsPerPage === value) {
			return;
		}

		this.firsPage = 0;
		this.rowsPerPage = value;
		this.rowsPerPageChange.emit(value);

		if (this.lazy) {
			if (this.lastPrimeNGTableEvent) {
				this.lastPrimeNGTableEvent.first = this.firsPage;
				this.lastPrimeNGTableEvent.rows = this.rowsPerPage;
			}

			this.loadLazyDataTable(this.lastPrimeNGTableEvent ? {
				...this.lastPrimeNGTableEvent,
				rowChange: true
			} : {
				first: this.firsPage,
				rows: this.rowsPerPage,
				rowChange: true
			});
		} else {
			this.data = this.data.slice();
			// this.loading = false;
		}
	}

	onRowSelectChange(event: any, isChecked?: boolean) {
		this.selectedRowsChange.emit(this.selectedRows);

		this.rowSelectChange.emit({
			primeNGEvent: event,
			isSelectAll: this.isAllDataSelected,
			clickedRowData: (event.data) ? event.data : undefined,
			selectedRows: this.selectedRows,
			isChecked: (event.checked || isChecked)
		});
	}

	handleOnRowReorder(event: TableRowReorderEvent) {
		/*console.log('onRowReorder: dragIndex = ', event.dragIndex, '; dropIndex = ', event.dropIndex);
		console.log('onRowReorder: data = ', this.data);

		if (this.isFirstRowReorder) {
			const tableData = this.data;
			tableData.forEach((value, index) => {
				value.order = index + 1;
			});

			console.log('onRowReorder: ** NEW ** data = ', tableData);

			this.data = tableData;

			this.isFirstRowReorder = false;
		}*/
		this.rowReorderEvent.emit(event);
	}

	cellEditModelChange(value: any, data: any, field: string) {
		ObjPropsStringDotNotationUtility.setObjValue(data, field, value);
	}

	onCellEditComplete(rowData: any) {
		this.cellEditComplete.emit(rowData);
	}

	getKeyFilter(columnType: ColumnType) {
		switch (columnType) {
			case ColumnType.Number:
				return 'num';
			case ColumnType.Money:
				return 'money';
			case ColumnType.Default:
			case ColumnType.String:
			case ColumnType.Date:
			default:
				return 'alphanum';
		}
	}

	exportAllToCsv = () => {
		this.exportToCsvRequested.emit(true);
	}

	transformColData(data: any, field: string) {
		const value = this.dataPipe.transform(data, field);
		let valueStr = '';

		if (typeof value === 'object') {
			const spacer = ', ';
			for (const key of Object.values(value)) {
				valueStr += `${key}${spacer}`;
			}

			const spacerLastIndex = valueStr.lastIndexOf(spacer);
			if (spacerLastIndex > 0) {
				valueStr = valueStr.substring(0, spacerLastIndex);
			}
		} else {
			valueStr = value;
		}
		return value === 'undefined' ? 'N/A' : valueStr;
	}

	private setExpandedRowColspan() {
		this.rowColspan = this.columns.length;
		if (this.expandableRows) {
			this.rowColspan++;
		}

		if (this.selectionCheckbox !== undefined) {
			this.rowColspan++;
		}

		if (this.tableActions !== undefined) {
			if (this.tableActions.length > 0) {
				const rowActionsTot = this.tableActions.filter(tableAction => tableAction.isRowAction !== false).length;
				if (rowActionsTot > 0) {
					this.rowColspan++;
				}
			}
		}
	}

	private generateTableActions(): void {
		// Reset all values when called, so that if an update duplicates are not created.
		this.rowActionsMenu = [];
		this.bulkActionsMenu = [];
		this.tableActionsMenu = [];

		if (this.tableActions !== undefined) {

			this.tableActions.forEach((tempAction: DataTableAction) => {
				const actionMenuItem: HeMenuItem = Object.assign({}, tempAction.menuItem);

				if (tempAction.callback !== undefined) {
					actionMenuItem.command = (event) => {
						const data = (!this.isBulkMode || this.selectedRows.length === 0) ? [this.rowActionData] : this.selectedRows;

						const callbackObj = {
							data: data,
							isAllDataSelected: this.isAllDataSelected,
							parentData: this.parentData
						};

						tempAction.callback(callbackObj);
					};
				}

				if (tempAction.isRowAction === undefined || tempAction.isRowAction === true) {
					if (this.rowActionsMenu === undefined) {
						this.rowActionsMenu = [];
					}

					this.rowActionsMenu.push(actionMenuItem);
				} else if (tempAction.isBulkAction === undefined || tempAction.isBulkAction === true) {
					if (this.bulkActionsMenu === undefined) {
						this.bulkActionsMenu = [];
					}

					// The presence of visibilityPredicate indicates that the item is predicated on a particular condition,
					// use the defined function to test whether the item is to be hidden or shown.
					if ((actionMenuItem.visibilityPredicate === undefined) || actionMenuItem.visibilityPredicate()) {
						this.bulkActionsMenu.push(actionMenuItem);
					}
				} else if (tempAction.isTableAction === true) {
					if (this.tableActionsMenu === undefined) {
						this.tableActionsMenu = [];
					}

					// The presence of visibilityPredicate indicates that the item is predicated on a particular condition,
					// use the defined function to test whether the item is to be hidden or shown.
					if ((actionMenuItem.visibilityPredicate === undefined) || actionMenuItem.visibilityPredicate()) {
						this.tableActionsMenu.push(actionMenuItem);
					}
				}
			});

			this.hasTableActions = this.tableActionsMenu && (this.tableActionsMenu.length > 0);

			if (this.bulkActionsMenu !== undefined) {
				if (this.bulkActionsMenu.length > 0 && this.selectionCheckbox !== undefined) {
					throw new Error('selectionCheckbox cannot be set if Data Table has Bulk Operations. Please set selectionCheckbox to undefined');
				}

				this.hasBulkActions = (this.bulkActionsMenu.length > 0);
			}
		}
	}

	private getURLSearchParams(data: DataTableLazyLoadEvent): HttpParams {
		let params: HttpParams = new HttpParams();

		params = params.set('$take', data.take.toString())
			.set('$offset', data.offset.toString());

		if (data.orderBy !== undefined) {
			params = params.set('$orderby', data.orderBy);
		}

		if (data.advancedSearchData !== undefined) {
			params = params.set('$filter', data.advancedSearchData.filterString);
		}

		return params;
	}

	getTitle(columnData: ColumnDataComponent): string {
		return columnData ? columnData.valueUsed : '';
	}
}

export type ValidRows = 5 | 10 | 25 | 50 | 100 | 250;
