<div class="draw-overhead-wrapper">
	<div *ngIf="drawResult" style="display: flex; align-items: center; column-gap: 12px;">
		<img *ngIf="drawResult?.gameGroupImageUrl; else defaultImage" [src]="drawResult?.gameGroupImageUrl"
			 alt="Game Group Image" width="100" height="100"/>

		<ng-template #defaultImage>
			<img [src]="noGameLotteryImageURL" alt="Game Group Default Image"/>
		</ng-template>

		<div style="display: grid; row-gap: 8px">
			<span style="font-size: 16px; font-weight: bold;">{{ drawResult?.gameGroupName }}</span>
			<span>{{ drawResult?.drawDate | date : 'EEEE, d MMMM, y, HH:mm zzzz' }}</span>
			<span>Draw ID: {{ drawResult?.drawID }}</span>
		</div>
	</div>

	<button *ngIf="submitLabel" pButton type="button" class="helio-orange-btn" [style.min-width.px]="'120px'"
			[class.he-disabled]="!drawResult.isDrawPublishable" [disabled]="loading"
			(click)="onPublishClicked()"
			[label]="submitLabel" [title]="submitLabel"
			icon="pi pi-clone"></button>
</div>

<he-data-table-v3 *ngIf="drawResult; else noDrawResultData" class="no-header-n-paginator" [dataKey]="dataKey"
				  [columns]="cols" [data]="data" [rowsPerPage]="25" [totalRecords]="totalRecords"
				  [tableActions]="[]" [tableMessage]="tableMessage" [searchFields]="[]" [loading]="false">
</he-data-table-v3>

<ng-template #noDrawResultData>
	<div style="padding: 12px;">{{tableMessage}}</div>
</ng-template>

<div *ngIf="drawResult"
	 style="display: flex; flex-wrap: wrap; justify-content: right; font-size: 16px; padding: 12px 8px;">
	<span style="font-weight: bold">Total Monetary Exposure:</span>&nbsp;
	<span>{{ drawResult?.totalMonetaryExposure | currency:drawResult?.currencySymbol:'symbol':'1.0-2':getSystemLocale() }}</span>
</div>
