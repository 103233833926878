import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SelectItem} from 'primeng/api';
import {BehaviorSubject, forkJoin, Observable, Subscription} from 'rxjs';
import {ComponentHelper} from '../../../interfaces/component.helper';
import {BRAND_NAME, TENANT_NAME} from '../../../constants/ui-db-name-mappings';
import {BaseLookup, OperatorsLookup, PlayerRegistrationTypesLookup} from '../../../interfaces/lookup-interfaces';
import {Currency} from '../../../models';
import {OperatorSettingDTO} from '../../../models/operator/operator-setting';
import {BoErrorHandlerService, ToastDisplayService} from '../../../../helio-core-services';
import {LookupService} from '../../../services/lookup.service';
import {OperatorSettingService} from '../../../services/operator-setting.service';
import {atLeastOneLowerCaseLetter, atLeastOneNumber, atLeastOneSymbol, atLeastOneUpperCaseLetter} from '../../../validators/validators';
import {RetailAgent, RetailAgentRequest} from '../../../models/retail-agent.model';
import {RetailAgentService} from '../../../services/retail-agent.service';

@Component({
	selector: 'he-retail-agent-editor-dialog',
	templateUrl: './retail-agent-editor-dialog.component.html',
	styleUrls: ['./retail-agent-editor-dialog.component.scss']
})
export class RetailAgentEditorDialogComponent extends ComponentHelper implements OnInit, OnDestroy {

	private _selectedAgent$ = new BehaviorSubject<RetailAgent>(null);
	private _agentToRepopulate$ = new BehaviorSubject<RetailAgentRequest>(null);

	TAG = RetailAgentEditorDialogComponent.name;
	formGroup: FormGroup;
	dialogTitle = 'Create Retail Agent';

	operatorLabel = TENANT_NAME.UI;
	brandLabel = BRAND_NAME.UI;

	tenantOpts: OperatorsLookup[] = [];
	agentTypeOpts: BaseLookup[] = [];
	brandOpts: SelectItem[] = [];
	currencyOpts: Currency[] = [];

	@Input() editorType: RetailAgentEditorType = RetailAgentEditorType.CREATE
	@Input() playerTypesOpts: PlayerRegistrationTypesLookup[] = [];

	isEdit = false;
	retailAgentRequest: RetailAgentRequest;

	isPasswordHidden = true;

	@Input() displayDialog = false;
	@Input() agentType: AgentType = AgentType.ADVANCE;

	@Output() dialogClose: EventEmitter<boolean> = new EventEmitter();
	@Output() submitEvent: EventEmitter<RetailAgentEditorData> = new EventEmitter();

	isLoadingLookups = false;

	selectOperatorPrompt = '';

	private updateRetailAgentSubs$: Subscription;
	private createRetailAgentSubs$: Subscription;
	private getAgentTypesSub$: Subscription;

	private _retailAgentFormSubs$: Subscription;
	private operatorSetting: OperatorSettingDTO;

	constructor(
		private retailAgentService: RetailAgentService,
		private lookupService: LookupService,
		private boErrorHandlerService: BoErrorHandlerService,
		private toastDisplayService: ToastDisplayService,
		private operatorSettingService: OperatorSettingService,
		private formBuilder: FormBuilder
	) {
		super(); //
	}

	@Input() set selectedAgent(value: RetailAgent) {
		this._selectedAgent$.next(value);
	}

	get selectedAgent(): RetailAgent {
		return this._selectedAgent$.getValue();
	}

	@Input() set agentStateToRestore(value: RetailAgentRequest) {
		this._agentToRepopulate$.next(value);
	}

	get agentStateToRestore(): RetailAgentRequest {
		return this._agentToRepopulate$.getValue();
	}

	get isRetailAgentFormValid(): boolean {
		return (!this.formGroup?.valid && (this.formGroup?.dirty || this.formGroup?.touched)) ||
			(!this.isEdit && !this.formGroup?.valid && this.formGroup?.untouched);
	}

	get disableOperatorDependency() {
		return (this.tenantOpts?.length < 1);
	}

	ngOnInit() {
		this.selectOperatorPrompt = 'Select ' + this.operatorLabel + ' First';

		/*this.breadcrumbService.setItems([
			{label: 'Retail Management'},
			{label: 'Agents', routerLink: ['/retail-agents/agents']},
			{label: 'Manage Agent'}
		]);*/

		// Flag Create or Update mode
		this.isEdit = this.editorType === RetailAgentEditorType.UPDATE;

		if (this.isEdit) {
			this.dialogTitle = `Update Retail Agent ${this.retailAgentRequest.retailAgentID}`;
			this.setFormData();
		} else if (this.formGroup) {
			// if form has not been created, false change flag , ignore
			this.dialogTitle = 'Create Retail Agent';
			this.initRetailAgentRequestObj();
			this.setFormCreateValidators();
		} else {
			console.error('Not in edit mode but Angular form is not initialised!');
		}

		// Edit flag
		this._selectedAgent$.asObservable().subscribe({
			next: (retailAgent => {
				if (retailAgent) {
					this.retailAgentRequest = retailAgent;
					this.agentType = this.retailAgentRequest.retailAgentTypeID; // When in EditMode, i.e. if model provided, base type on it
				}
			})
		});

		// Create flag
		this._agentToRepopulate$.asObservable().subscribe({
			next: (retailAgentRequest => {
				if (retailAgentRequest) {
					this.retailAgentRequest = retailAgentRequest;
					this.agentType = this.retailAgentRequest.retailAgentTypeID
				}
			})
		});

		this.operatorSettingService.getSettings().subscribe({
			next: operatorSetting => {
				this.operatorSetting = operatorSetting;
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
			}
		});

		this.initRetailAgentRequestObj();
		this.initRetailAgentForm();
	}

	ngOnDestroy() {
		this.releaseSubscriptions(this.updateRetailAgentSubs$, this.createRetailAgentSubs$, this.getAgentTypesSub$);
	}

	initRetailAgentRequestObj() {
		this.retailAgentRequest = new RetailAgentRequest();
		// this.retailAgentRequest.retailAgentID = 0;
	}

	onSaveClicked(): void {
		const request = this.getRetailAgentFormData();
		const data: RetailAgentEditorData = {type: 'create', request};

		if (this.isEdit) {
			data.type = 'update';
			this.submitEvent.emit(data);
			return;
		}

		this.submitEvent.emit(data);
	}

	showPassword(shouldShow: boolean) {
		this.isPasswordHidden = !shouldShow;
	}

	onAgentTypeSelected(e: any) {
		this.agentType = e.value;
		this.initRetailAgentForm();
	}

	/**
	 * @param currentSelectedOperatorID Optional. Pass the currently selected operatorID as set on UI, to indicate to this method that
	 * an attempt should be made to set the default currency and brand, {@link agentStateToRestore} is not present.
	 */
	onTenantSelected(currentSelectedOperatorID?: number) {
		this.currencyOpts = [] // Clear any previously pushed currencies

		const tenantID = this.formGroup.get('tenant').value;

		const observableList: Observable<any>[] = [
			this.retailAgentService.getOperatorCurrencies(tenantID),
			this.lookupService.getOperatorBrands(tenantID)
		];

		if (this.isTypeAdvance) {
			this.isLoadingLookups = true;

			forkJoin(observableList).subscribe({
				next: responseList => {
					this.currencyOpts = responseList[0]
					this.brandOpts = responseList[1]

					const trySetDefaultBrand = () => {
						// Determine the default brand to show - use object marked with isDefault (if exists). Else the first element???
						const defaultBrand = this.brandOpts.find(entry => {
							return (entry as any).isDefault;
						}) ?? this.brandOpts[0];

						if (defaultBrand) {
							this.formGroup.get('brand').setValue(defaultBrand.value);
							this.retailAgentRequest.tenantBrandID = defaultBrand.value;
						}

						// Separate to above, as brand could be default or pulled from top of len > 1
						handleBrandDisableState()
					}

					const trySetDefaultCurrency = () => {
						if (this.currencyOpts?.length > 0) {
							this.formGroup.get('currencyID').setValue(this.currencyOpts[0].value);
							this.retailAgentRequest.currencyID = Number(this.currencyOpts[0].value);

							handleCurrencyDisableState()
						}
					}

					const handleBrandDisableState = () => {
						if (this.brandOpts?.length === 1) {
							this.formGroup.controls['brand'].disable()
							this.formGroup.get('brand').updateValueAndValidity()
						}
					}

					const handleCurrencyDisableState = () => {
						if (this.currencyOpts?.length === 1) {
							this.formGroup.controls['currencyID'].disable()
							this.formGroup.get('currencyID').updateValueAndValidity()
						}
					}

					if (this.agentStateToRestore) {
						if (this.agentStateToRestore?.currencyID) {
							this.formGroup.get('currencyID').setValue(this.agentStateToRestore.currencyID);
							this.retailAgentRequest.currencyID = this.agentStateToRestore.currencyID;
						} else if (this.agentStateToRestore?.tenantID && currentSelectedOperatorID) {
							trySetDefaultCurrency() // To avoid "Select Operator First" label when switching between AgentType
						}

						if (this.agentStateToRestore?.tenantBrandID) {
							this.formGroup.get('brand').setValue(this.agentStateToRestore.tenantBrandID);
							this.retailAgentRequest.tenantBrandID = this.agentStateToRestore.tenantBrandID;
						} else if (this.agentStateToRestore?.tenantID && currentSelectedOperatorID) {
							trySetDefaultBrand() // To avoid "Select Operator First" label when switching between AgentType
						}

						handleCurrencyDisableState()
						handleBrandDisableState()
					} else {
						trySetDefaultCurrency()
						trySetDefaultBrand()
					}

					this.formGroup.updateValueAndValidity()

					this.isLoadingLookups = false;
				},
				error: error => {
					this.boErrorHandlerService.handleError(error);
					this.isLoadingLookups = false;
				}
			});
		}
	};

	onBrandSelected(event: any) {
		// console.warn(this.TAG, '#onBrandSelected:', event);
	}

	closeDialog() {
		this.dialogClose.emit(true);
	}

	private getRetailAgentFormData(): RetailAgentRequest {
		this.retailAgentRequest.retailAgentTypeID = this.formGroup.get('agentType').value;
		this.retailAgentRequest.fullName = this.formGroup.get('fullName').value;
		this.retailAgentRequest.tenantID = this.formGroup.get('tenant').value;

		if (this.isTypeAdvance) {
			this.retailAgentRequest.tenantID = this.formGroup.get('tenant').value;
			this.retailAgentRequest.tenantBrandID = this.formGroup.get('brand').value;
			this.retailAgentRequest.currencyID = this.formGroup.get('currencyID').value;
			this.retailAgentRequest.email = this.formGroup.get('email').value;
			this.retailAgentRequest.address = this.formGroup.get('address').value;
			this.retailAgentRequest.isActive = this.formGroup.get('isActive').value;

			if (!this.isEdit) {
				this.retailAgentRequest.username = this.formGroup.get('username').value;
				this.retailAgentRequest.password = this.formGroup.get('password').value;
				this.retailAgentRequest.requirePasswordChange = this.formGroup.get('requirePasswordChange').value;
				this.retailAgentRequest.playerRegistrationTypeIDs = this.formGroup.get('playerType').value;
			}
		}

		return this.retailAgentRequest;
	}

	private setFormData(): void {
		this.formGroup.get('fullName')?.setValue(this.retailAgentRequest.fullName);

		if (this.isTypeAdvance) {
			this.formGroup.get('email')?.setValue(this.retailAgentRequest.email);
			this.formGroup.get('address')?.setValue(this.retailAgentRequest.address);
			this.formGroup.get('username')?.setValue(this.retailAgentRequest.username);
			this.formGroup.get('isActive')?.setValue(this.retailAgentRequest.isActive);
			this.formGroup.get('currencyID')?.setValue(this.retailAgentRequest.currencyID);

			this.formGroup.get('username')?.disable();
		}
	}

	get isTypeAdvance() {
		return this.agentType === AgentType.ADVANCE;
	}

	private initRetailAgentForm(): void {
		this.fetchLookups();

		if (this._retailAgentFormSubs$) {
			this._retailAgentFormSubs$.unsubscribe();
		}

		const agentType = this.agentType ?? ''
		const fullName = this.agentStateToRestore?.fullName ?? ''
		const tempTenantID = this.agentStateToRestore?.tenantID ?? undefined

		if (this.agentType === AgentType.ADVANCE) {
			this.formGroup = this.formBuilder.group({
				agentType: new FormControl(agentType),
				username: new FormControl(this.agentStateToRestore?.username ?? ''),
				fullName: new FormControl(fullName, [Validators.required]),
				email: new FormControl(this.agentStateToRestore?.email ?? '', [Validators.required, Validators.email]),
				currencyID: new FormControl({
					value: this.agentStateToRestore?.currencyID ?? undefined,
					disabled: false
				}, [Validators.required]),
				address: new FormControl(this.agentStateToRestore?.address ?? ''),
				tenant: new FormControl(tempTenantID, [Validators.required]),
				brand: new FormControl({value: this.agentStateToRestore?.tenantBrandID ?? undefined, disabled: false}),
				playerType: new FormControl(this.agentStateToRestore?.playerRegistrationTypeIDs ?? [], [Validators.required]),
				password: new FormControl(''),
				isActive: new FormControl(this.agentStateToRestore?.isActive ?? false),
				requirePasswordChange: new FormControl(this.agentStateToRestore?.requirePasswordChange ?? false)
			});

			this._retailAgentFormSubs$ = this.formGroup.get('tenant').valueChanges.subscribe(tenantID => {
				// Find the corresponding operatorObj
				const operatorObj = this.tenantOpts.find(entry => {
					return entry.tenantID === tenantID;
				});

				// Match the default playerType
				const defaultPlayerType = this.playerTypesOpts.find(entry => {
					return operatorObj?.defaultPlayerRegistrationTypeID === entry?.playerRegistrationTypeID;
				})

				if (defaultPlayerType) {
					this.formGroup.get('playerType').setValue([defaultPlayerType.playerRegistrationTypeID]);
				}
			});
		} else if (this.agentType === AgentType.SIMPLE) {
			this.formGroup = this.formBuilder.group({
				agentType: new FormControl(agentType),
				tenant: new FormControl(tempTenantID, [Validators.required]),
				fullName: new FormControl(fullName, [Validators.required]),
			});
		} else {
			throw new Error('Unhandled AgentType');
		}

		// to trigger fetching of dependent lookups
		if (tempTenantID) {
			this.onTenantSelected(tempTenantID)
		}

		this.formGroup.get('agentType').setValue(agentType); // this.retailAgent.fullName
		this.formGroup.get('agentType').updateValueAndValidity();
		this.formGroup.updateValueAndValidity();
	}

	// TODO: Pass this on as an @Input from Agents, so that the lookups are shared between parent <==> child (although this isn't
	//  a big issue since LookupService employs singleton - what to do??

	/**
	 * Attach create-specific validators
	 */
	private setFormCreateValidators(): void {
		this.formGroup.get('username').setValidators([Validators.required, Validators.minLength(6)]);
		this.formGroup.get('tenant').setValidators([Validators.required]);
		this.formGroup.get('password').setValidators([
			Validators.required,
			Validators.minLength(8),
			Validators.maxLength(128),
			atLeastOneLowerCaseLetter(),
			atLeastOneUpperCaseLetter(),
			atLeastOneNumber(),
			atLeastOneSymbol()
		]);
	}

	//  But at least, #forkJoin should be discontinued as this is atomic and not required here!
	private fetchLookups(): void {
		this.isLoadingLookups = true;

		this.releaseSubscriptions(this.getAgentTypesSub$);

		const observableList: Observable<any>[] = [
			this.lookupService.getOperators(),
		];

		if (this.isEdit) {
			observableList.push(this.retailAgentService.getRetailAgentByID(this.retailAgentRequest.retailAgentID));
		}

		forkJoin(observableList).subscribe({
			next: responseList => {
				this.tenantOpts = responseList[0] // [responseList[0][0]]

				if (this.tenantOpts?.length === 1) {
					const defaultTenant = this.tenantOpts[0]

					this.formGroup.get('tenant').setValue(defaultTenant.value);
					this.formGroup.get('tenant').disable()
					this.formGroup.get('tenant').updateValueAndValidity();
					this.formGroup.updateValueAndValidity();

					// Make call only if a tenantID has been selected as this will
					// make request for brand and currency lookups which requires it
					if (defaultTenant) {
						this.onTenantSelected(defaultTenant.tenantID)
					}
				}

				this.isLoadingLookups = false;
			},
			error: error => {
				this.boErrorHandlerService.handleError(error, undefined, 'Failed to load lookup items');
				this.isLoadingLookups = false;
			}
		});

		// get agent types
		this.getAgentTypesSub$ = this.lookupService.getAgentTypes().subscribe(
			{
				next: (res) => {
					this.agentTypeOpts = res;

					this.formGroup.get('agentType').setValue(this.agentType); // this.retailAgent.fullName
					this.formGroup.get('agentType').updateValueAndValidity();
				},
				error: error => {
					this.boErrorHandlerService.handleError(error);
				}
			});
	}
}

/**
 * @summary Modelled on api/retailAgents/retailAgentTypes
 */
export enum AgentType {
	ADVANCE = 1, // matching API values
	SIMPLE
}

export class RetailAgentEditorData {
	type: 'create' | 'update';
	request: RetailAgentRequest;
}

export enum RetailAgentEditorType {
	CREATE = 'create',
	UPDATE = 'update'
}
