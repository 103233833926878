<he-base-dialog [header]="'Change Draw Date'" [(visible)]="visible" [isLoadingLookups]="false"
				[style]="{'max-width': '550px', width: '550px'}" (visibleChange)="closeDialog()"
				[rejectLabel]="'Close'" [submitLabel]="'Change Date'" (submitEvent)="onSubmit()"
				[disableSubmit]="isFormDisabled">
	<p style="margin-bottom: 28px;">
		To manually change the date and time of this specific draw, please provide the new date and time, and the
		reason for doing so.
	</p>

	<form [formGroup]="formGroup" class="dialog-form-placement" autocomplete="off">
		<label>Original Draw Date</label>
		<span class="he-disabled">{{originalDrawDate | date: dateFormat : timezoneOffset}}</span>

		<!--Date display and UI on PrimeNG can only be displayed as local time,
		However, this is converted to UTC before passing onto server.-->
		<label>New Draw Date</label>
		<!--[minDate]="minimumDrawDate"-->
		<p-calendar formControlName="newDrawDate" [showIcon]="true" dateFormat="dd/mm/yy" appendTo="body"
					readonlyInput="readonlyInput" [showTime]="true" [showSeconds]="true"></p-calendar>

		<label for="reason">Reason</label>
		<!--placeholder="Reason"-->
		<textarea id="reason" formControlName="reason" pInputTextarea type="text" rows="5" cols="30"></textarea>
	</form>
</he-base-dialog>
