import {
	AfterViewInit,
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	EventEmitter,
	Input, OnDestroy, OnInit,
	Output,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {SearchEventType, SubmitSearchEvent} from './submit-search-event.model';

import {SearchField} from './search-field.model';
import {SearchFieldType} from './search-field-type.enum';
import {AdvancedSearchFieldHostDirective} from './advanced-search-field-host.directive';
import {AdvancedSearchFieldComponent} from './advanced-search-field';
import {AdvancedSearchService, ToastDisplayService} from '../../../../helio-core-services';
import {ToastMessageType} from '../../../models/general';
import {FILTER_OPT_NOT_SELECTED_TOAST, SEARCH_OPT_NOT_SELECTED_TOAST, UNDEFINED_LOOKUP_TOAST} from '../../../constants';
import {capitalise} from '../../../utilities/general-utilities/string.utility';

@Component({
	selector: 'he-advanced-search',
	styleUrls: [
		'./advanced-search.component.scss'
	],
	templateUrl: './advanced-search.component.html'
})
export class AdvancedSearchComponent implements OnInit, AfterViewInit, OnDestroy {

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private toastDisplayService: ToastDisplayService,
		private advancedSearchService: AdvancedSearchService
	) {
	}

	TAG = AdvancedSearchComponent.name;
	@Input() searchFields: SearchField[];
	@Input() componentType: SearchType = SearchType.ADVANCED_SEARCH;
	@Output() searchEvent: EventEmitter<SubmitSearchEvent> = new EventEmitter<SubmitSearchEvent>();
	@ViewChild('searchFieldHost', {
		read: AdvancedSearchFieldHostDirective,
		static: false
	}) searchFieldHost: AdvancedSearchFieldHostDirective;
	searchFieldToAdd: SearchField;
	searchFieldComponents: AdvancedSearchFieldComponent[] = [];
	searchFieldComponentFactory: ComponentFactory<AdvancedSearchFieldComponent>;
	// which is then used to identify the component reference on delete
	searchFieldCount = 0;
	// used to generate a unique ID for each new search field component,
	hasEmptySearchValues = false;

	private urlPath = window.location.hash;

	protected readonly capitalise = capitalise;

	protected readonly SearchType = SearchType;

	ngOnInit() {
		this.removeAllSearchFieldComponentsList()
	}

	ngAfterViewInit() {
		this.searchFieldComponentFactory = this.componentFactoryResolver.resolveComponentFactory(AdvancedSearchFieldComponent);
		setTimeout(() => {
			this.readSearchFields();
		}, 0);
	}

	ngOnDestroy() {
		this.removeAllSearchFieldComponentsList()
	}

	readSearchFields() {
		if (!this.advancedSearchService.fields.get(this.urlPath)) {
			return;
		}

		const viewContainerRef: ViewContainerRef = this.searchFieldHost.viewContainerRef;

		// const length = this.advancedSearchService.fields.get(this.urlPath).length;
		this.advancedSearchService.fields.get(this.urlPath).forEach((f, i) => {
			const componentRef = viewContainerRef.createComponent(this.searchFieldComponentFactory);
			const componentInstance = componentRef.instance;
			componentInstance.searchField = Object.assign({}, f);
			componentInstance.searchFieldComponentRef = componentRef;
			componentInstance.searchFieldID = ++this.searchFieldCount;
			componentInstance.isFirstSearchField = (this.searchFieldComponents.length === 0);
			componentInstance.selectedSearchAndOr = f.selectedSearchAndOr;
			componentInstance.destroyedComponent.subscribe(id => this.updateSearchFieldComponentsList(id));

			this.searchFieldComponents.push(componentInstance);
		});

		setTimeout(() => {
			this.submitSearchClick();
		}, 0);
	}

	submitSearchClick() {
		const filter = this.getFilterStringForSubmit();

		if (filter) {
			this.searchEvent.emit(filter);
		} else {
			this.searchEvent.emit();
		}

		this.advancedSearchService.setShowAdvancedSearch(this.urlPath, true);
		this.advancedSearchService.fields.set(this.urlPath, this.searchFieldComponents.map(fc => {
			fc.searchField.selectedSearchAndOr = fc.selectedSearchAndOr;
			return fc.searchField;
		}));

		if (this.hasEmptySearchValues) {
			this.toastDisplayService.addMessage({
				type: ToastMessageType.info,
				title: 'Info Message',
				description: 'Your search query was submitted, but note that empty search values are ignored. Are you sure this was not a mistake?'
			});
		}
	}

	clearSearchClick() {
		// clear data in searchFields, emit undefined
		this.searchEvent.emit({
			searchFields: this.searchFields,
			searchType: SearchEventType.CLEAR
		});
		this.resetSearchFields();
		this.advancedSearchService.fields.delete(this.urlPath);
	}

	addSearchField() {
		if (this.searchFieldToAdd === undefined) {
			this.toastDisplayService.addMessage({
				type: ToastMessageType.error,
				title: 'Error Message',
				description: this.componentType === SearchType.ADVANCED_SEARCH
					? SEARCH_OPT_NOT_SELECTED_TOAST : FILTER_OPT_NOT_SELECTED_TOAST
			});
		} else {
			const viewContainerRef: ViewContainerRef = this.searchFieldHost.viewContainerRef;
			const componentRef = viewContainerRef.createComponent(this.searchFieldComponentFactory);

			const componentInstance = componentRef.instance;
			componentInstance.searchField = Object.assign({}, this.searchFieldToAdd);
			componentInstance.searchFieldComponentRef = componentRef;
			componentInstance.searchFieldID = ++this.searchFieldCount;
			componentInstance.isFirstSearchField = (this.searchFieldComponents.length === 0);

			componentInstance.destroyedComponent.subscribe(id => this.updateSearchFieldComponentsList(id));

			this.searchFieldComponents.push(componentInstance);
			this.searchFieldToAdd = undefined;
		}
	}

	closeSearchClick() {
		// this.clearSearchClick();
		this.searchEvent.emit({
			searchFields: this.searchFields,
			searchType: SearchEventType.CLOSE
		});
		// this.advancedSearchService.setShowAdvancedSearch(this.urlPath, false);
	}

	onSelectSearchFieldClicked() {
		let isMissingLookup = false;

		for (const field of this.searchFields) {
			if ('listOptions' in field && (!field.listOptions || field.listOptions.length === 0)) {
				isMissingLookup = true;
				break;
			}
		}

		if (isMissingLookup) {
			this.toastDisplayService.showWarningToast(UNDEFINED_LOOKUP_TOAST);
			return;
		}
	}

	private resetSearchFields(): void {
		this.searchFieldComponents.forEach(temp => {
			const searchField = temp.searchField;

			if ((searchField.type === SearchFieldType.Number) && searchField.isRange && typeof searchField.searchValue !== 'string') {
				const resetRange = [
					searchField.minNumberRange,
					searchField.maxNumberRange
				];

				searchField.searchValue = resetRange.slice();
				temp.numberRangeMinValue = searchField.minNumberRange;
				temp.numberRangeMaxValue = searchField.maxNumberRange;
			} else {
				searchField.searchValue = undefined;
			}

			searchField.filterString = undefined;
		});
	}

	public getFilterStringForSubmit(): SubmitSearchEvent {
		this.hasEmptySearchValues = false;
		let filterString;

		this.searchFieldComponents.forEach((temp, i) => {
			const searchFilterString = temp.searchField.filterString;
			if (searchFilterString !== undefined) {
				if (i === 0) {
					filterString = searchFilterString;
				} else {
					filterString += ` ${temp.selectedSearchAndOr} ${searchFilterString}`;
				}
			} else {
				this.hasEmptySearchValues = true;
			}
		});

		return !filterString ? null : {
			searchFields: this.searchFields,
			searchType: SearchEventType.SUBMIT,
			filterString: filterString,
			urlParams: new HttpParams().set('$filter', filterString)
		};
	}

	private updateSearchFieldComponentsList(id: number) {
		const index = this.searchFieldComponents.findIndex(temp => temp.searchFieldID === id);
		this.searchFieldComponents.splice(index, 1);

		if (index === 0 && this.searchFieldComponents.length > 0) {
			this.searchFieldComponents[0].isFirstSearchField = true;
		}
	}

	private removeAllSearchFieldComponentsList() {
		this.advancedSearchService.fields.clear()
	}
}

/**
 * @summary assigned values should not be changed as these are used with UI!
 */
export enum SearchType {
	ADVANCED_SEARCH = 'search',
	FILTER = 'filter'
}
