<!--region Draw Dialogs-->

<!--Though not strictly required, *ngIf="" on the dialogs ensure that the views are destroyed when no longer visible,
    (1) keeps the DOM clean (2) Ensures that previous data of a particular row does not persistent-->
<he-drop-draw-dialog [dropType]="'cancel'" [drawID]="gameDraw?.drawID"
					 [visible]="displayCancelDrawDialog" (visibleChange)="displayCancelDrawDialog = false"
					 (submitEvent)="cancelDraw($event);"></he-drop-draw-dialog>

<he-change-draw-date [draw]="gameDraw" [(visible)]="displayChangeDrawDateDialog"
					 (visibleChange)="displayChangeDrawDateDialog = false"
					 (submitEvent)="changeDrawDate($event)"></he-change-draw-date>

<he-manual-draw-entry-dialog entryType="insertion" [draw]="gameDraw"
							 [gameFormat]="gameFormat"
							 (visibleChange)="displayInsertResultDialog = false"
							 (submitEvent)="insertManualResults($event)"
							 [visible]="displayInsertResultDialog"></he-manual-draw-entry-dialog>

<he-drop-draw-dialog [dropType]="'void'" [drawID]="gameDraw?.drawID"
					 [visible]="displayVoidDrawDialog" (visibleChange)="displayVoidDrawDialog = false"
					 (submitEvent)="voidDraw($event);"></he-drop-draw-dialog>

<he-manual-draw-entry-dialog entryType="approval" [draw]="gameDraw"
							 [gameFormat]="gameFormat" [lotteryResults]="lotteryResults"
							 (visibleChange)="displayApproveResultDialog = false"
							 (submitEvent)="approveManualResults($event)"
							 [visible]="displayApproveResultDialog"></he-manual-draw-entry-dialog>
<!--endregion-->

<div *ngIf="gameDraw?.drawID != undefined && gameDraw?.drawID > 0; else undefinedDrawState"
	 id="root" [ngClass]="{'standard': !compactStyle}">
	<!--For current NextDraw and PreviousDraw cards when in SpecificDraw view-->
	<div *ngIf="compactStyle && menuItems && menuItems.length > 0" class="page-opts-style-2" style="padding-top: 12px;">
		<button *ngFor="let item of menuItems" class="helio-orange-btn" pButton type="button"
				(click)="item.command()" [disabled]="item.disabled" [icon]="item.icon"
				[label]="item.label" [title]="item.label"></button>
	</div>

	<div class="game-details card" [ngClass]="{'compact': compactStyle}">
		<div id="title-info-wrapper">
			<div id="title-wrapper">
				<div id="awaiting-action"
					 *ngIf="(drawType === DrawTypeEnum.ACTIVE) && gameDraw?.drawsAwaitingAction > 0">
					{{ gameDraw.drawsAwaitingAction + ' draws awaiting action' }}
				</div>

				<h1 *ngIf="!compactStyle">{{ gameDraw.gameGroupName }}</h1>
			</div>

			<ng-container *ngIf="drawType === DrawTypeEnum.ACTIVE">
				<div class="draw-schedule">
					<span>{{ gameDraw.drawInterval + ' ' }}</span>{{ dayAndTimeOfNextDraw }}
				</div>

				<div class="card-subtitle">Sales end {{ saleEndsIn }} before draw</div>

				<!--'+0000': specifies that the date should be displayed in UTC (i.e., with a zero offset from UTC)-->
				<div class="card-subtitle">This specific draw is scheduled
					for {{ gameDraw['drawDate'] | date : this.appConfigService.defaultDateFormat : '+0000'}}
				</div>
			</ng-container>

			<div *ngIf="drawType === DrawTypeEnum.PREVIOUS" class="draw-schedule card-subtitle">
				Draw took place as scheduled
				on {{ gameDraw?.drawDate | date : this.appConfigService.defaultDateFormat : '+0000' }}
			</div>
		</div>

		<!--region Settings cog-->
		<ng-container>
			<div *ngIf="!compactStyle" class="actions-menu-cell">
				<p-menu #menu [popup]="true" [model]="menuItems" appendTo="body"></p-menu>

				<button type="button" pButton class="helio-orange-btn action-menu" icon="ui-icon-settings"
						(click)="menu.toggle($event);"></button>
			</div>
		</ng-container>
		<!--endregion-->

		<div class="image-wrapper">
			<img *ngIf="gameDraw?.gameGroupImageUrl; else defaultImage" [src]="gameDraw?.gameGroupImageUrl"
				 alt="Game Group Image"/>

			<ng-template #defaultImage>
				<img [src]="noGameLotteryImageURL" alt="Game Group Default Image"/>
				<!--<div class="text-image">
					<span class="text-image-span">{{ gameDraw?.gameGroupName }}</span></div>-->
			</ng-template>
		</div>

		<div class="draw-id-and-result-wrapper">
			<ng-container *ngIf="drawType === DrawTypeEnum.PREVIOUS">
				<!--If res is not available, hide the views with visibility, so that their place is still retained,
					and the card placement appears consistent-->
				<span [style.visibility]="gameDraw?.result ? 'visible' : 'hidden'">Results:</span>
				<span [style.visibility]="gameDraw?.result ? 'visible' : 'hidden'" class="result">
					{{ gameDraw?.result ?? 'pending' }}
				</span>
			</ng-container>

			<span>Draw ID: {{ gameDraw?.drawID }}</span>
		</div>

		<div class="draw-details">
			<!-- TODO: The exact details of the impl for type 1 and 2 are still unclear, hence wait before trying to
					   apply dry here!! -->
			<ng-container *ngIf="drawType === DrawTypeEnum.ACTIVE">
				<span>Jackpot</span>
				<!--gameDraw?.gameCurrencyCode ?? gameDraw?.currencyCode is temp solution whilst waiting for
				GetUpcomingDrawsOverview -> gameCurrencyCode and GetLatestPreviousDraws -> currencyCode to be aligned in BE-->
				<span
					class="jackpot">{{ gameDraw.estimatedJackpot | currency:(gameDraw?.gameCurrencyCode ?? gameDraw?.currencyCode):'symbol':'1.0-0' }}</span>

				<span>Lines in Draw</span>
				<span>{{ gameDraw.drawLines | number:'1.0-0' }}</span>

				<span>Draw in</span>
				<span *ngIf="gameDraw?.drawDate !== undefined">{{ countdownClock }}</span>
			</ng-container>

			<ng-container *ngIf="drawType === DrawTypeEnum.PREVIOUS">
				<!--<span>Total Winners</span>
				<span>{{gameDraw.totalWinners | number:'1.0-0'}}</span>-->

				<span>Lines in Draw</span>
				<span>{{ gameDraw.drawLines | number:'1.0-0' }}</span>

				<span>Winning Lines</span>
				<span>{{ gameDraw.winningLines | number:'1.0-0' }}</span>

				<span>Money Awarded</span>
				<span
					class="jackpot">{{ gameDraw.prizeMoneyAwarded ?? 0 | currency:(gameDraw?.gameCurrencyCode ?? gameDraw?.currencyCode):'symbol':'1.0-0' }}</span>
			</ng-container>
		</div>
	</div>
</div>

<ng-template #undefinedDrawState>
	<div class="skeleton-style-cls">
		<p-skeleton width="100%" height="4rem"></p-skeleton>
	</div>
</ng-template>
